<script setup lang="ts">
import { store } from "@/entities";
import { doRequest } from "@/helpers";
import { textInput, selectInput, textAreaComp } from "@/share/UI";
import { ref, computed, PropType } from "vue";
import { loader } from "@/widgets";
import TreeTasks from "./components/TreeTasks.vue"

const loaderState = ref(false);
const readonly = ref(false);
const userInfo = computed(() => store.state.user.info);
const today = ref(getDate());

function getDate() {
  const regex = new RegExp(/(\d{4}-\d{2}-\d{2})/);
  const mutch = new Date().toISOString().match(regex);
  if (mutch?.length) {
    return mutch[0];
  } else return "";
}
const priorities = [
  {
    name: "Высокий",
    value: 1,
  },
  {
    name: "Обычный",
    value: 2,
  },
];

const payload = ref({
  updateInfo: {
    id: 0,
    title: "",
    description: "",
    deadLine: "",
    statusId: 0,
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  } as Omit<iStore.TaskInfo, "status" | "createdAt" | "log" | "files">,
  priorityId: 0
});

const task = computed(() => {
  if (store.state.tasks.task) {
    payload.value.updateInfo = {
      id: store.state.tasks.task.info.id,
      title: store.state.tasks.task.info.title,
      description: store.state.tasks.task.info.description,
      deadLine: store.state.tasks.task.info.deadLine,
      statusId: store.state.tasks.task.info.statusId,
    }
    payload.value.priorityId = store.state.tasks.task.priority.id
  }
  return store.state.tasks.task
})

const date = ref(task.value?.info.deadLine?.split("T")[0]);

function setTime(e: Event) {
  const { value } = e.target as HTMLInputElement;
  payload.value.updateInfo.deadLine = `${payload.value.updateInfo.deadLine?.split("T")[0]}T${value}:00`;
}

function setDate(e: Event) {
  const { value } = e.target as HTMLInputElement;
  if (payload.value.updateInfo.deadLine && /23:59:59/.test(payload.value.updateInfo.deadLine)) {
    payload.value.updateInfo.deadLine = `${value}T${payload.value.updateInfo.deadLine?.split("T")[1]}`;
    return;
  }
  payload.value.updateInfo.deadLine = `${value}T23:59:59`;
}


function setRowsCount(val: string) {
  if (val) {
    if (val.length > 10 && val.length < 499) {
      return 6;
    } else if (val.length > 500 && val.length < 999) {
      return 9;
    } else if (val.length > 999) {
      return 12;
    }
  } else {
    return 2;
  }
}
function createRequest(e: Event) {
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/tasks/update", {
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST",
    body: JSON.stringify(payload.value),
  })
    .then(() => {
      store.commit("componentLoader/TOGGLE_STATE");
      loaderState.value = false;
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
      loaderState.value = false;
    });
}

function enableEdit() {
  readonly.value = true
}


function disableEdit() {
  readonly.value = false
}
</script>

<template>
  <loader v-if="loaderState" />
  <div v-else class="main" v-if="task">
    <div>
      <p class="info-title">Тема</p>
      <div v-if="task.creator.id === userInfo.id">
        <textInput :textVal="payload.updateInfo.title" :readonly="!readonly"
          @inputVal="(value: string) => (payload.updateInfo.title = value.charAt(0).toUpperCase() + value.slice(1))"
          />
      </div>
      <div v-else>
        <p>{{ task.info.title }}</p>
      </div>
    </div>
    <div>
      <p class="info-title">Описание</p>
      <div>
        <p class="descriptionText" v-if='!readonly'>{{ task.info.description && task.info.description.length ? task.info.description : 'Не указано' }}</p>
        <textAreaComp v-else :textVal="payload.updateInfo.description" :readonly="!readonly" @inputVal="(value: string) => (payload.updateInfo.description = value.charAt(0).toUpperCase() + value.slice(1))"></textAreaComp>
      </div>
    </div>
    <div>
      <p class="info-title">Приоритет</p>
      <div v-if="task.creator.id === userInfo.id">
        <selectInput v-if="readonly" :selected="payload.priorityId" :listOptions="priorities"
          @inputVal="(value: number) => (payload.priorityId = value)"></selectInput>
        <p v-else>{{ task.priority.name }}</p>
      </div>
      <div v-else>
        <p>{{ task.priority.name }}</p>
      </div>
    </div>
    <div>
      <p class="info-title">Дата исполнения</p>
      <div v-if="task.creator.id === userInfo.id && readonly" class="date-time">
        <input type="date" id="_date" :value="date" @change="setDate" required :min="today" />
        <input type="time" id="_time" @change="setTime" />
      </div>
      <div v-else>
        <p v-if="task.info.deadLine">
          {{
            /[0]{2,}\:[0]{2,}\:[0]{2,}/.test(task.info.deadLine)
              ? new Date(task.info.deadLine).toLocaleString("ru", { dateStyle: "medium" })
              : new Date(task.info.deadLine).toLocaleString("ru", {
                dateStyle: "medium", timeStyle: "medium"
              }).split(",").join(" ")
          }}
        </p>
        <p v-else>Не установлено</p>
      </div>
    </div>
    <div>
      <div v-if="readonly" class="info__action">        
        <button v-if="task.creator.id === userInfo.id && task.info.status.id !== 4" @click="disableEdit">Отменить</button>
        <button v-if="task.creator.id === userInfo.id && task.info.status.id !== 4" @click="createRequest">Сохранить</button>
      </div>
      <div v-else class="info__action">
        <button v-if="task.creator.id === userInfo.id && task.info.status.id !== 4" @click="enableEdit">Редактировать</button>
      </div>
    </div>
    <!-- <TreeTasks v-if="task && task.treeId" :task="task"/> -->
  </div>
</template>
<style scoped lang="scss">
.main {
  padding: 1em;
  width: 90%;

  & div {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0.5em;

    & .info-title {
      color: var(--third-text-color);
      padding: 0 0.5em;
      margin: 0;
    }

    & .date-time {
      flex-direction: row;

      input {
        justify-self: auto;
        padding: 0.5em;
        border: 0.1em solid var(--fourth-border-color);
        outline: none;
        border-radius: 0.4em;
        word-wrap: break-word !important;
        background-color: var(--secondary-background-color);
      }
    }

    & div {
      width: 100%;

      & p {
        margin: 0;
        padding: 0.5em;
        border: 0.1em solid transparent;
        outline: none;
        border-radius: 0.4em;
        word-wrap: break-word !important;
        background-color: transparent;
      }
    }
  }
}

.info {
  &__action {

    display: flex;
    flex-direction: row !important;
    gap: 1em;

    & button {
      width: 10em;
      padding: .5em .3em;
      margin: 0;
      display: block;
      gap: 1em;
      border: none;
      outline: none;
      background-color: var(--primary-color);
      color: var(--text-color);
      border-radius: 0.4em;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }

      &:hover {
        background-color: var(--primary-hover-color);
      }
    }
  }
}

.descriptionText {
  display: -webkit-box;
  max-height: 4em;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
</style>
