<script lang="ts" setup></script>

<template>
  <div class="connection">
    <div class="connection__wrp">
      <div>
        <div class="connection__logo">
          <p>
            <img :src="require(`@/share/assets/icons/logo.svg`)" alt="logo" />
            <span>ServiceDesk</span>
          </p>
        </div>        
    <div >
      <div v-if="!$store.state.user.failReconnect" class="connection-wrp">
        <h1>Интернет-соединение прервано</h1>
        <h3>Попытка соединения</h3>
        <h3>{{ $store.state.user.connectAttempt }} из 10-ти</h3>
      </div>
      <div v-else class="connection-wrp">
        <h1>Интернет-соединение прервано</h1>
        <h3>Проверьте, пожалуйста, статус подключения провайдера.</h3>
      </div>
    </div>
      </div>
    </div>
    <div class="register__tech">
      <p>Техническая поддержка: support@sdesk.online</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .connection__form {
    width: 90%;
  }

  h1 {
    font-size: 1.5em !important;
  }

  .connection__wrp {
    width: calc(90% - 2em);
    padding: 1em;
  }

  .register__tech {
    bottom: 1em;
    width: 90%;
    text-align: center;
    color: var(--secondary-text-color);
    font-size: 1em;
  }

  .connection__logo {
    font-size: 1.5em;

    & p {
      gap: 0.5em;

      & img {
        width: 1.5em;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .connection__form {
    width: 80%;
  }

  .connection__wrp {
    width: 40%;
    padding: 2em;
    gap: 2em;
  }

  .register__tech {
    position: fixed;
    bottom: 0em;
    left: 50%;
    transform: translate(-50%);
    font-size: 1.1em;
  }

  .connection__logo {
    font-size: 3em;

    & p {
      gap: 0.5em;

      & img {
        width: 2em;
      }
    }
  }
}

.connection {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);

  & h3 {
    text-align: center;
  }
}

.connection__wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--third-background-color);
  color: var(--text-color);
  border-radius: 0.4em;

  & h1 {
    text-align: center;
  }

  & div {
    width: 100%;
  }
}

.connection__logo {
  display: flex;
  justify-content: center;

  & p {
    margin: 0.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    & img {
      display: block;
      aspect-ratio: 1/1;
    }

    & span {
      color: var(--primary-color);
    }
  }
}

.view {
  background: url("@/share/assets/icons/viewIcon.svg") center / cover no-repeat;
}

.noview {
  background: url("@/share/assets/icons/noViewIcon.svg") center / cover no-repeat;
}
</style>