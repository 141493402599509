<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { capitalFirstLetter } from "@/helpers/capitalLetter";
import { ref, onMounted } from "vue";

const store = useStore();

const payload = ref({
  name: "",
  shortName: "",
});

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "positions") store.commit("titles/CLOSE_MODAL");
}

onMounted(() => {
  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape") store.commit("titles/CLOSE_MODAL");
  });
});

function createPos() {
  doRequest("/personal/create/position", {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload.value),
  })
    .then(({ response }) => {
      payload.value.name = "";
      payload.value.shortName = "";
      store.commit("titles/CLOSE_MODAL");
      store.commit("titles/SET_POSITION_ID", response);
    })
    .catch((error) => {
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}
</script>

<template>
  <div class="positions" @mousedown="close" @keyup.esc="close">
    <div>
      <div class="positions-wrp">
        <h3>Создать должность</h3>
        <div>
          <label>Полное наименование:</label>
          <input type="text" v-model="payload.name" @input="capitalFirstLetter" />
        </div>
        <div>
          <label>Короткое наименование:</label>
          <input type="text" v-model="payload.shortName" @input="capitalFirstLetter" />
        </div>
        <div class="positions__buttons">
          <button @click="close($event, true)">Закрыть</button>
          <button
            :disabled="payload.name.length < 3 || payload.shortName.length < 3"
            :class="{ 'active-button': payload.name.length > 2 && payload.shortName.length > 2 }"
            @click="createPos"
          >
            Создать
          </button>
        </div>
      </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.positions {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkening-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 7;

  & div {
    position: relative;
  }

  & .positions-wrp {
    border-radius: 0.4em;
    padding: 2em 5em;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2em;
    background-color: var(--secondary-background-color);

    & div {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      width: 100%;
    }

    & input {
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
    }

    & select {
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    .selected {
      background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
    }

    .unselected {
      background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
    }

    & button {
      display: block;
      padding: 1em 0;
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--button-deactivate-color);
      color: var(--text-color);
      border-radius: 0.4em;

      &:hover {
        color: var(--text-hover-color);
      }
    }
  }
}

.positions__buttons {
  display: flex;
  flex-direction: row !important;
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.active-button {
  background: var(--primary-color) !important;
  cursor: pointer !important;
  transition: transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
