<script setup lang="ts">
import { computed, PropType } from "vue";
import { ITaskInfo } from "../test";

const props = defineProps({
  type: {
    type: String as PropType<"created" | "executed">,
    required: true,
  },
  tasks: {
    type: Array as PropType<ITaskInfo[]>,
    required: true,
  },
});

const step = computed(() => {
  const result = {
    count: 0,
    value: 0,
  };

  result.count = props.tasks.length > 10000 ? 5000 : 0;

  return result;
});
</script>

<template>
  <div class="bar"></div>
</template>

<script lang="scss" scoped></script>
