<script lang="ts" setup>
import { computed, onMounted, PropType, ref, StyleValue } from "vue";
import { generateData, ITaskInfo } from "../test";

const _gantt = ref<HTMLDivElement | null>(null);

const props = defineProps({
  tasks: {
    type: Array as PropType<ITaskInfo[]>,
    required: true,
  },
});

const tasks = computed(() => props.tasks.filter((t) => t.executorId === 2));

const selectedYear = ref(new Date().getFullYear());
const months = ref(["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]);
const daysCountInYear = ref(Math.floor((new Date(selectedYear.value, 11, 31).getTime() - new Date(selectedYear.value, 0, 0).getTime()) / 86400000));
const defaultSize = ref(15.36);

const size = computed({
  get() {
    const viewPort = window.visualViewport;
    if (viewPort) {
      defaultSize.value = viewPort.width * 0.012;
    }
    return defaultSize.value;
  },
  set(val) {
    defaultSize.value = val as number;
  },
});
const x = ref(0);
const min = ref(0);
const mouseDown = ref(false);

const showTaskInfo = ref(false);
const infoCoordinates = ref({
  x: 0,
  y: 0,
  status: "",
  class: "",
  deadLine: "",
  dueDate: null as null | string,
});

function getDates() {
  const result = [];
  const daysCountInMonths: number[][] = [];
  const today = new Date().toISOString().split("T")[0];
  for (let i = 0; i < months.value.length; i++) {
    if (!Array.isArray(daysCountInMonths[i])) daysCountInMonths[i] = [];
    for (let d = 1; d <= new Date(selectedYear.value, i + 1, 0).getDate(); d++) {
      daysCountInMonths[i].push(d);
    }
  }
  let left = 0;
  for (let i = 0; i < daysCountInMonths.length; i++) {
    for (let d = 1; d <= daysCountInMonths[i].length; d++) {
      const day = new Date(selectedYear.value, i, d);
      const str = day.getDate().toString().padStart(2, "0");
      result.push({
        current: `${selectedYear.value}-${(i + 1).toString().padStart(2, "0")}-${str}` === today,
        value: str,
        position: (d + left) * size.value,
        day: `${selectedYear.value}-${(i + 1).toString().padStart(2, "0")}-${str}`,
        dayOff: day.getDay() === 0 || day.getDay() === 6,
      });
    }
    left = left + new Date(selectedYear.value, i + 1, 0).getDate();
    if (i === 9) min.value = result[result.length - 5].position * -1;
  }
  return result;
}

const parsedDates = ref(getDates());

function getStyleOfTask(task: ITaskInfo) {
  const result: StyleValue = {};
  result.position = "absolute";
  result.top = `-.6em`;
  const position = parsedDates.value.findIndex((d) => d.day === task.info.createdAt.split("T")[0]);
  if (position === -1) return;
  result.left = `${x.value + parsedDates.value[position].position + size.value * 5.7}px`;
  let finished = null;
  let deleted = null;
  let updated = null;
  let deadLine = null;
  const created = position;
  if (task.info.finished) {
    finished = parsedDates.value.findIndex((d) => d.day === task.info.finished!.split("T")[0]);
  }
  if (task.info.deletedAt) {
    deleted = parsedDates.value.findIndex((d) => d.day === task.info.deletedAt!.split("T")[0]);
  }
  if (task.info.updatedAt) {
    updated = parsedDates.value.findIndex((d) => d.day === task.info.updatedAt!.split("T")[0]);
  }
  if (task.info.deadLine) {
    deadLine = parsedDates.value.findIndex((d) => d.day === task.info.deadLine!.split("T")[0]);
  }
  let width = 0;
  if (updated) {
    width = parsedDates.value[updated].position - parsedDates.value[position].position;
  }
  if (task.info.statusId === 6) {
    if (finished === updated && deadLine === null) {
      result.background = `linear-gradient(to right, #ABDFB3 50%, #ABDFB3 50%)`;
    }
    if (deadLine) {
      if (deadLine && finished && deadLine < finished) {
        const range = finished - created;
        const diff = finished - deadLine;
        const lated = (diff / range) * 100;
        result.background = `linear-gradient(to right, #ABDFB3 ${0}%, #ABDFB3 ${100 - lated}%, #EB4242 ${100 - lated}%, #EB4242 ${100}%)`;
      } else if (deadLine && finished && deadLine > finished) {
        const range = finished - created;
        const diff = deadLine - finished;
        const lated = (diff / range) * 100;
        result.outline = ".1em #ABDFB3 solid";
        result.outlineOffset = "-.1em";
        result.background = `linear-gradient(to right, #ABDFB3 ${0}%, #ABDFB3 ${100 - lated}%, transparent ${100 - lated}%, transparent ${100}%)`;
      }
    }
  }
  if (task.info.statusId === 2) {
    const current = parsedDates.value.findIndex((d) => d.day === new Date().toISOString().split("T")[0]);
    width = parsedDates.value[current].position - parsedDates.value[position].position;
    result.backgroundColor = "#F79236";
  }
  if (task.info.statusId === 9) {
    if (finished) {
      result.backgroundColor = "#979DA0";
    }
  }
  if (task.info.statusId === 4) {
    result.backgroundColor = "#FFD700";
  }
  result.width = `${width}px`;
  return result;
}

function getLeftPositionValue(index: number) {
  const days = new Date(selectedYear.value, index, 0).getDate();
  let previous = 0;

  for (let i = 0; i < index; i++) {
    previous = previous + new Date(selectedYear.value, i, 0).getDate();
  }

  const result = size.value * (days + index > 0 ? previous : 0) + (size.value * days) / 2 + x.value;

  return `${result}px`;
}

function mousedown(e: MouseEvent) {
  mouseDown.value = true;
}
function mouseup(e: MouseEvent) {
  mouseDown.value = false;
}
function mouseleave(e: MouseEvent) {
  mouseDown.value = false;
}
function mousemove(e: MouseEvent) {
  if (mouseDown.value) {
    x.value += e.movementX * 2;
    if (x.value < min.value) return (x.value = min.value);
    if (x.value > 0) return (x.value = 0);
  }
}

function closeInfo() {
  showTaskInfo.value = false;
}

const titles = ["день", "дня", "дней"];

function declOfNum(number: number) {
  const cases = [0, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

function getInfo(e: MouseEvent, task: ITaskInfo) {
  let dueDate: null | string = "";
  let _class = "";
  let status = "";
  let deadLine = "";
  if (task.info.statusId === 6) {
    if (task.info.finished) {
      const range = Math.floor((Date.parse(task.info.finished.split("T")[0]) - Date.parse(task.info.createdAt.split("T")[0])) / 86400000);
      dueDate = `Время выполнения: ${range || 1} ${declOfNum(range)}`;
      _class = "finished";
      status = "Исполнено";
    }
  }
  if (task.info.statusId === 9) {
    if (task.info.updatedAt) {
      const range = Math.floor((Date.parse(task.info.updatedAt.split("T")[0]) - Date.parse(task.info.createdAt.split("T")[0])) / 86400000);
      dueDate = `Затрачено: ${range || 1} ${declOfNum(range)}`;
      _class = "canceled";
      status = "Отменено";
    }
  }
  if (task.info.statusId === 2) {
    _class = "inProgress";
    status = "В работе";
    const current = Date.now();
    if (task.info.deadLine) {
      deadLine = task.info.deadLine.split("T")[0].split("-").reverse().join("-");
      const deadline = Date.parse(task.info.deadLine.split("T")[0]);
      if (deadline > current) {
        const range = Math.floor((deadline - current) / 86400000);
        dueDate = `Просрочено на ${range | 1} ${declOfNum(range)}`;
      } else {
        const range = Math.floor((current - deadline) / 86400000);
        dueDate = `Осталось: ${range || 1} ${declOfNum(range)}`;
      }
    } else {
      const range = Math.floor((current - Date.parse(task.info.createdAt.split("T")[0])) / 86400000);
      dueDate = `${range | 1} ${declOfNum(range)}`;
    }
  }
  if (task.info.statusId === 4) {
    if (task.info.finished) {
      const range = Math.floor((Date.parse(task.info.finished.split("T")[0]) - Date.parse(task.info.createdAt.split("T")[0])) / 86400000);
      dueDate = `Время выполнения: ${range || 1} ${declOfNum(range)}`;
      _class = "confirmation";
      status = "На подтверждении";
    }
  }

  infoCoordinates.value = {
    x: e.pageX,
    y: e.pageY,
    dueDate: dueDate,
    deadLine,
    class: _class,
    status: status,
  };
  showTaskInfo.value = true;
}

function moveInfo(e: MouseEvent) {
  if (showTaskInfo.value) {
    infoCoordinates.value.x += e.movementX;
    infoCoordinates.value.y += e.movementY;
  }
}

onMounted(() => {
  window.addEventListener("resize", (e) => {
    const { target } = e as UIEvent & { target: Window };
  });
  if (_gantt.value) {
    const date = new Date();
    const index = parsedDates.value.findIndex((d) => d.day === `${date.getFullYear()}-${date.getMonth().toString().padStart(2, "0")}-15`);
    _gantt.value.style.height = `${window.innerHeight * 0.64}px`;
    _gantt.value.style.setProperty("--cus-height", `${(size.value + 3.805) * tasks.value.length}px`);
    if (index !== -1 && parsedDates.value) {
      x.value = parsedDates.value[index].position * -1;
    }
  }
});
</script>
<template>
  <div class="gantt">
    <div class="gantt__wrp" @mousedown="mousedown" @mouseup="mouseup" @mouseleave="mouseleave" @mousemove="mousemove">
      <div class="gantt__empty"></div>
      <div class="gantt__calendar">
        <p v-for="(month, index) in months" :key="index" :style="{ left: getLeftPositionValue(index), color: '#979DA0' }">{{ month }}</p>
        <p
          v-for="(days, index) in parsedDates"
          :key="index"
          :style="{
            padding: '0',
            margin: '0',
            left: `${x + days.position - (days.current ? 3 : 0)}px`,
            top: days.current ? '2.4em' : '3em',
            color: days.dayOff ? '#979DA0' : '#F79236',
            fontSize: days.current ? '1.2em' : 'unset',
          }"
        >
          {{ days.value }}
        </p>
      </div>
      <div class="gantt__tasks__wrp" ref="_gantt">
        <div class="gantt__tasks__bg">
          <span
            v-for="(d, index) in parsedDates"
            :key="index"
            :style="{ left: `calc(${x + d.position}px + 8.5em)`, borderLeft: d.current ? `.13em #979da0 solid` : `.1em #979da030 solid` }"
          ></span>
        </div>
        <div class="gantt__tasks__task" v-for="(task, index) in tasks" :key="index">
          <p>{{ task.info.title }}</p>
          <p :style="getStyleOfTask(task)" @mouseover="(e) => getInfo(e, task)" @mouseleave="closeInfo" @mousemove="moveInfo">&nbsp;</p>
        </div>
      </div>
      <div
        v-if="showTaskInfo"
        class="gantt__tasks__task__info"
        :style="{ top: `${infoCoordinates.y}px`, left: `${infoCoordinates.x}px` }"
        :class="{ [infoCoordinates.class]: true }"
      >
        <p>{{ infoCoordinates.status }}</p>
        <p>{{ infoCoordinates.deadLine }}</p>
        <p>{{ infoCoordinates.dueDate }}</p>
        <p></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gantt {
  width: 100%;
  user-select: none;
  overflow: hidden;
}

.gantt__wrp {
  display: grid;
  grid-template-columns: 8% 1fr;
  grid-template-rows: 4.5em 1fr;
  grid-template-areas: "empty calendar" "task task";
}

.gantt__empty {
  grid-area: empty;
}

.gantt__calendar {
  grid-area: calendar;
  display: flex;
  position: relative;
  overflow: hidden;

  & p {
    position: absolute;
  }
}

.gantt__tasks__wrp {
  grid-area: task;
  position: relative;
  background-color: var(--background-color);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--background-color);
  }
}

.gantt__tasks__task {
  position: relative;
  display: flex;
  flex-direction: column;

  & p {
    margin: 0.6em 0;
  }

  & p:nth-child(1) {
    width: 8.4em;
    margin: 0;
    padding: 0 0 0.6em 0;
    justify-self: flex-start;
    position: relative;
    text-align: center;
    background-color: var(--background-color);
    z-index: 3;
    font-weight: bold;
  }

  & p:nth-child(2) {
    z-index: 1;
    position: absolute;
  }
}

.gantt__tasks__bg {
  & span {
    position: absolute;
    height: var(--cus-height);
    z-index: 1;
  }
}

.gantt__tasks__task__info {
  padding: 0.5em 0.5em 0.5em 3em;
  width: max-content;
  height: max-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  border-top-right-radius: 0.8em;
  gap: 0.3 em;
  color: #202020;

  & p {
    margin: 0;
  }
}

.finished {
  background: #fff url("@/share/assets/icons/task_done.svg") no-repeat 5% 25%;
}

.canceled {
  background: #fff url("@/share/assets/icons/task_canceled.svg") no-repeat 5% 25%;
}

.inProgress {
  background: #fff url("@/share/assets/icons/task_progress.svg") no-repeat 5% 25%;
}

.confirmation {
  background: #fff url("@/share/assets/icons/task_confirmation.svg") no-repeat 5% 25%;
}
</style>
