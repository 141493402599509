<script lang="ts" setup>
import { PropType, ref } from "vue";
import CircleDiagram from "./CircleDiagram.vue";
import { IValueProperties } from "../test";

const props = defineProps({
  accepted: {
    type: Number as PropType<number>,
    required: true,
  },
  onProgress: {
    type: Number as PropType<number>,
    required: true,
  },
  awaited: {
    type: Number as PropType<number>,
    required: true,
  },
  timeout: {
    type: Number as PropType<number>,
    required: true,
  },
});

const data = ref({
  accepted: {
    value: props.accepted,
    color: "#ABDFB3",
    title: "Исполнено",
  },
  onProgress: {
    value: props.onProgress,
    color: "#F79236",
    title: "В работе",
  },
  awaited: {
    value: props.awaited,
    color: "#FFD700",
    title: "На подверждении",
  },
  timeout: {
    value: props.timeout,
    color: "#EB4242",
    title: "Просрочено",
  },
});

const dataSum = ref(data.value.accepted.value + data.value.onProgress.value + data.value.awaited.value + data.value.timeout.value);

const dataPersent = ref({
  acceptedPersent: Math.round((data.value.accepted.value / dataSum.value) * 100 * 10) / 10,
  onProgressPersent: Math.round((data.value.onProgress.value / dataSum.value) * 100 * 10) / 10,
  awaitedPersent: Math.round((data.value.awaited.value / dataSum.value) * 100 * 10) / 10,
  timeoutPersent: Math.round((data.value.timeout.value / dataSum.value) * 100 * 10) / 10,
});

const month = "октябрь";

const averageTime = ref("13 ч. 49 м.");
</script>

<template>
  <div class="analytics">
    <h1 class="title">Текущий месяц ({{ month }})</h1>
    <div class="analytics-wrp">
      <div class="analytics__data">
        <div>
          <p>Общее количество задач</p>
          <p style="color: #70ae79">Количество выполненных задач</p>
          <p style="color: #f79236">Количество задач в работе</p>
          <p style="color: #d9b90e">Количество задач в ожидании</p>
          <p style="color: #eb4242">Количество просроченных задач</p>
          <p>Среднее время выполнения задачи</p>
        </div>
        <div>
          <p>{{ dataSum }}</p>
          <p style="color: #70ae79">{{ data.accepted.value }} {{ `(${dataPersent.acceptedPersent}%)`.replace(".", ",") }}</p>
          <p style="color: #f79236">{{ data.onProgress.value }} {{ `(${dataPersent.onProgressPersent}%)`.replace(".", ",") }}</p>
          <p style="color: #d9b90e">{{ data.awaited.value }} {{ `(${dataPersent.awaitedPersent}%)`.replace(".", ",") }}</p>
          <p style="color: #eb4242">{{ data.timeout.value }} {{ `(${dataPersent.timeoutPersent}%)`.replace(".", ",") }}</p>
          <p>{{ averageTime }}</p>
        </div>
      </div>
      <CircleDiagram :data="data" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analytics {
  display: flex;
  flex-direction: column;
}

.analytics > div > p {
  font-size: 1.4em;
  font-weight: 500;
}

.analytics-wrp {
  display: flex;

  & div {
    flex-basis: 50%;
    align-self: flex-start;
  }

  & div:nth-child(2) {
    flex-basis: 13%;
  }
}

.analytics__data {
  display: flex;

  & div {
    flex-basis: 50%;
  }
}
</style>
