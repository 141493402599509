import { Module } from "vuex";

const randomClassMap = {
  1: "orange",
  2: "grey",
  3: "black",
  4: "red",
  5: "darkgray",
}

export const chatState: Module<iChat.Chat, iStore.RootState> = {
  namespaced: true,
  mutations: {
    TYPINGS(state, payload: { roomName: string, shortName: string, personalId: number }) {
      const type = /^org/.test(payload.roomName) ? "public" : /^global/.test(payload.roomName) ? "global" : "private"
      const index = state.list.findIndex(p => p.roomName === payload.roomName)
      if (index === -1) return
      if (type === "private") {
        if (state.list[index].typing.length) return state.list[index].typing[0].counter = 3000
        state.list[index].typing.push({ name: "Печатает...", counter: 3000, id: payload.personalId })
      } else {
        const personIndex = state.list[index].typing.findIndex(p => p.id === payload.personalId)
        if (personIndex !== -1) return state.list[index].typing[personIndex].counter = 3000
        else state.list[index].typing.push({ name: payload.shortName, counter: 3000, id: payload.personalId })
      }
    },
    SET_TASK_MESSAGE_ID(state, payload) {
      state.isTask = true
      state.selectedMessageId = payload
    },
    SET_EDITED_MESSAGE(state, payload) {
      const mIndex = state.messages.findIndex(m => m.id === payload.id)
      const lIndex = state.list.findIndex(p => p.lastMessage.id === payload.id)
      if (mIndex !== -1) state.messages[mIndex] = payload
      if (lIndex !== -1) state.list[lIndex].lastMessage = payload
    },
    DELETE_MESSAGE(state, payload: { messageId: number, roomName: string }) {
      const mIndex = state.messages.findIndex(m => m.id === payload.messageId)
      const lIndex = state.list.findIndex(p => p.lastMessage.id === payload.messageId)
      if (mIndex !== -1) {
        state.messages[mIndex].files = []
        state.messages[mIndex].message = "Сообщение удалено"
        state.messages[mIndex].deleted = true
      }
      if (lIndex !== -1) {
        state.list[lIndex].lastMessage.message = "Сообщение удалено"
        state.list[lIndex].lastMessage.deleted = true
      }
    },
    SET_SELF_ID(state, payload) {
      state.selfId = payload.id
      state.selfOrgId = payload.orgId
      state.selfName = payload.shortName
    },
    SET_ORGS_LIST(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        state.list.push({
          class: randomClassMap[Math.floor(Math.random() * 5) as keyof typeof randomClassMap],
          fullName: payload[i].shortName,
          lastMessage: {} as any,
          lastSeen: "",
          roomName: `${state.selfOrgId === payload[i].id ? "org" : "global"}__${[...new Set([state.selfOrgId, payload[i].id])].sort().join("_")}__`,
          online: false,
          abbreviation: `ОБ`,
          user: payload[i].user,
          orgId: payload[i].id,
          personalId: 0,
          shortName: payload[i].shortName,
          typing: [],
          counter: 0,
        })
      }
    },
    SET_PERSONAL_LIST(state, payload: iStore.Personal[]) {
      if (!state.list) state.list = []
      const list = mapList(payload, state.selfId)
      state.list = [...state.list, ...list]
      const index = state.list.findIndex(p => p.personalId === state.selfId)
      if (index !== -1) state.selfName = state.list[index].shortName
    },
    SET_MESSAGE_ID(state, payload) {
      state.selectedMessageId = payload
    },
    SET_TYPE_OF_MESSAGE(state, payload) {
      state.deleteMessageType = payload
    },
    SET_MESSAGE(state, payload: { roomName: string, message: iStore.SocketsMessages }) {
      const index = state.list.findIndex(p => p.roomName === payload.roomName)
      if (index !== -1) {
        state.list[index].lastMessage = payload.message
        state.list[index].typing = []
      }
      if (state.selectedRoom === payload.roomName) {
        state.messages.push(payload.message)
      }
      state.list.sort((x, y) => new Date(y.lastMessage?.createdAt || "0").getTime() - new Date(x.lastMessage?.createdAt || "0").getTime())
    },
    SET_SELECTED_PERSON(state, payload) {
      state.selectOrgId = payload.orgId
      state.selectedRoom = payload.roomName
    },
    SET_ONLINE(state, payload) {
      if (!state.list) state.list = []
      for (let i = 0; i < payload.length; i++) {
        for (let a = 0; a < state.list.length; a++) {
          if (state.list[a].personalId === payload[i].personalId) {
            state.list[a].online = payload[i].status;
            state.list[a].lastSeen = payload[i].timestamp;
          }
        }
      }
    },
    SET_OFFLINE(state, payload) {
      for (let i = 0; i < state.list.length; i++) {
        if (state.list[i].personalId === payload.personalId) {
          state.list[i].online = false;
          state.list[i].lastSeen = payload.lastSeen;
          return;
        }
      }
    },
    SET_MESSAGE_TO_READED(state, payload) {
      const index = state.messages.findIndex(m => m.id === payload.messageId)
      if (index !== -1) state.messages[index].readed = true
      const index2 = state.list.findIndex(m => m.lastMessage.id === payload.messageId)
      if (index2 !== -1) state.list[index2].lastMessage.readed = true
    },
    SET_MESSAGES(state, payload: iStore.SocketsMessages[]) {
      state.messages = payload.reverse()
    },
    SET_ALL_LAST_MESSAGES(state, payload: iStore.SocketsMessages[]) {
      if (payload && Array.isArray(payload)) {
        for (let i = 0; i < payload.length; i++) {
          const index = state.list.findIndex((p) => p.roomName === payload[i].roomName)
          if (index !== -1) state.list[index].lastMessage = payload[i]
        }
      }
      state.list.sort((x, y) => new Date(y.lastMessage?.createdAt || "0").getTime() - new Date(x.lastMessage?.createdAt || "0").getTime())
    }
  },
  actions: {
    CHECK_TYPINGS({ state }) {
      if (state.checkrunner) return
      state.checkrunner = true
      const interval: NodeJS.Timeout = setInterval(() => {
        let counter = 0
        for (let i = 0; i < state.list.length; i++) {
          if (state.list[i].typing.length) {
            counter++
            for (let c = 0; c < state.list[i].typing.length; c++) {
              if (state.list[i].typing[c].counter > 0) state.list[i].typing[c].counter = state.list[i].typing[c].counter - 500
              else {
                state.list[i].typing.splice(c, 1)
                counter--
              }
            }
          }
        }
        if (!counter) {
          state.checkrunner = false
          for (let i = 0; i < state.list.length; i++) {
            if (state.list[i].typing.length) {
              return
            }
          }
          return clearInterval(interval);
        }
      }, 500)
    }
  }
};


function mapList(payload: iStore.Personal[], selfId: number) {
  const res = []
  for (let i = 0; i < payload.length; i++) {
    if (selfId === payload[i].id) continue
    res.push({
      class: randomClassMap[Math.floor(Math.random() * 5) as keyof typeof randomClassMap],
      fullName: `${payload[i].lastName} ${payload[i].firstName} ${payload[i].surName}`,
      lastMessage: {} as any,
      lastSeen: "",
      roomName: `private__${[selfId, payload[i].id].sort().join("_")}__`,
      online: false,
      abbreviation: `${payload[i].lastName[0]}${payload[i].firstName[0]}`,
      user: payload[i].user,
      orgId: payload[i].orgId,
      personalId: payload[i].id,
      shortName: `${payload[i].lastName} ${payload[i].firstName[0]}. ${payload[i].surName[0]}.`,
      typing: [],
      counter: 0
    })
  }
  return res
}