<script lang="ts" setup>
import { useStore } from "@/entities";
import { sockets } from "@/entities";
import { computed, PropType, ref } from "vue";

const store = useStore();

const selected = computed(() => store.state.personal.selected);

const emit = defineEmits(["selected"]);

defineProps({
  list: {
    type: Array as PropType<iChat.Person[]>,
    required: true,
  },
});

function getMessages(roomName: string) {
  store.commit("chat/SET_SELECTED_PERSON", { roomName });
  store.state.chat.messages = [];
  sockets.emit("getPrivateMessages", { roomName });
}

function getLastMessage(message: iStore.SocketsMessages) {
  if (!message?.id) return { message: `Нет сообщений`, delivered: null };
  return {
    message: `${message.senderId === store.state.user?.info.id && message.message ? "Вы: " : ""}${message.message}`,
    file: `${message.senderId === store.state.user?.info.id && message.files.length > 0 ? "Вы: " : ""}${
      message.files.length > 0 ? message.files[message.files.length - 1].filename : ""
    }`,
    delivered: message.delivered,
    readed: message.hasReaded && Array.isArray(message.hasReaded) && message.hasReaded.includes(store.state.personal.id) ? true : message.readed,
    senderId: message.senderId,
    time: new Date(message.createdAt).toLocaleString("ru", { timeStyle: "short" }),
  };
}

function getTyper(list: { name: string; counter: number }[]) {
  if (list.length > 1) {
    if (list.length <= 2) {
      return `${list.map((l) => l.name).join(" и ")} печатают`;
    } else `${list[0].name}, ${list[1].name} и еще ${list.length - 2} печатают`;
  }
  return list.map((l) => (l.name === "Печатает..." ? l.name : `${l.name} печатает...`)).join(" ");
}
</script>

<template>
  <div class="chat__personal">
    <!-- <div class="chat__personal__search">
        <input type="text" placeholder="Поиск по имени" />
      </div> -->
    <div class="chat__personal__list">
      <div
        class="chat__personal__list__member"
        v-for="(person, index) in list"
        :key="index"
        @click="getMessages(person.roomName)"
        :class="{ 'selected-reciver': selected === person.personalId, online: person.online }"
      >
        <div class="chat__personal__list__member__avatar">
          <img v-if="person.user?.avatar" class="chat__personal__list__member__avatar__image" :src="person.user?.avatar" />
          <p v-else class="chat__personal__list__member__avatar__image" :class="person.class">
            {{ person.abbreviation }}
          </p>
        </div>
        <div class="chat__personal__list__member__name">
          <p>{{ person.shortName }}</p>
          <p
            :class="{ 'deleted-message': person.lastMessage && person.lastMessage.deleted }"
            v-if="getLastMessage(person.lastMessage).message && !getLastMessage(person.lastMessage)?.file"
          >
            {{ person.typing.length ? getTyper(person.typing) : getLastMessage(person.lastMessage).message }}
          </p>
          <p :class="{ 'deleted-message': person.lastMessage && person.lastMessage.deleted }" v-else-if="getLastMessage(person.lastMessage)?.file">
            {{ getLastMessage(person.lastMessage).file }}
          </p>
        </div>
        <div class="chat__personal__list__member__status">
          <p>{{ getLastMessage(person.lastMessage).time }}</p>
          <p
            v-if="getLastMessage(person.lastMessage).delivered !== null"
            :class="{
              delivered:
                getLastMessage(person.lastMessage).delivered &&
                !getLastMessage(person.lastMessage).readed &&
                getLastMessage(person.lastMessage).senderId === $store.state.user?.info.id,
              readed: getLastMessage(person.lastMessage).readed && getLastMessage(person.lastMessage).senderId === $store.state.user?.info.id,
              'new-message': !getLastMessage(person.lastMessage).readed && getLastMessage(person.lastMessage).senderId !== $store.state.user?.info.id,
            }"
          ></p>
          <p v-else>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .chat__personal__list {
    width: calc(100vw) !important;
  }

  .chat__personal__list__member {
    gap: 0.5em;
  }

  .chat__personal__list__member__name {
    & > p:nth-child(1) {
      font-size: 0.9em;
    }

    & > p:nth-child(2) {
      font-size: 0.9em;
      max-width: 15em !important;
    }
  }

  .chat__personal__list__member__status {
    & > p:nth-child(1) {
      font-size: 0.9em;
    }
  }

  .chat__personal__list__member__avatar {
    & p,
    img {
      font-size: 1.1em;
      width: 2em;
      color: var(--text-color);
    }
  }
}

@media screen and (min-width: 480px) {
  .chat__personal {
    flex-basis: 30%;
  }

  .chat__personal__list__member {
    gap: 1em;
  }

  .chat__personal__list {
    max-height: calc(100vh - 9em);

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-color);
      border-radius: 0.5em;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--background-color);
    }
  }

  .chat__personal__list__member__avatar {
    & p,
    img {
      font-size: 1.2em;
      width: 2em;
      color: var(--text-color);
    }
  }
}

.chat__personal {
  display: flex;
  flex-direction: column;
}

.chat__personal__search {
  display: flex;

  & input {
    flex-grow: 1;
    outline: none;
    display: block;
    border: none;
    background-color: transparent;
    border: 0.1em solid var(--fourth-border-color);
    border-radius: 0.7em;
    padding: 1em 1.5em;
  }

  & input:nth-child(1) {
    flex-basis: 25%;
    background-image: url("@/share/assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 5%;
  }
}

.chat__personal__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chat__personal__list__member {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em 1em;
  border-bottom: 0.1em solid var(--fourth-color);

  & p {
    margin: 0;
  }
}

.chat__personal__list__member:hover {
  background-color: var(--background-chat-hover-color);
}

.chat__personal__list__member__avatar {
  & p,
  img {
    display: flex;
    margin: 0;
    padding: 0;
    padding: 0.4em;

    aspect-ratio: 1/1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: var(--fourth-background-color);
    border-radius: 50%;
    color: var(--text-color);
  }
}

.chat__personal__list__member__name {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  word-wrap: break-word;

  & > p:nth-child(1) {
    font-weight: bold;
    color: var(--secondary-text-color);
  }

  & > p:nth-child(2) {
    max-width: 20em;
    color: var(--third-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.chat__personal__list__member__status {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  & > p:nth-child(1) {
    color: var(--fourth-text-color);
  }
}

.new-message {
  width: 1em;
  margin: 0;
  padding: 0;
  aspect-ratio: 1/1;
  background-color: #f79236;
  border-radius: 50%;
}

.deleted-message {
  font-style: italic;
}

.orange {
  background-color: #f79236 !important;
  color: var(--secondary-background-color) !important;
}

.grey {
  background-color: #738086 !important;
  color: var(--secondary-background-color) !important;
}

.black {
  background-color: #2f3c42 !important;
  color: var(--secondary-background-color) !important;
}

.red {
  background-color: #cd5322 !important;
  color: var(--secondary-background-color) !important;
}

.darkgray {
  background-color: #2f3c42 !important;
  color: var(--secondary-background-color) !important;
}
</style>
