<script setup lang="ts">
import { PropType, ref, watchEffect } from "vue";

const emit = defineEmits(["close"]);
const context = ref();
const X = ref();
const Y = ref();

const props = defineProps({
  display: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  top: {
    type: Number as PropType<number>,
    required: true,
  },
  left: {
    type: Number as PropType<number>,
    required: true,
  },
});

function close() {
  emit("close");
}
watchEffect(() => {
  if (context.value) {
    context.value.focus();
    if (props.left !== 0 && props.top !== 0) {
      const menuRect = context.value.getBoundingClientRect();
      if (menuRect) {
        const viewportHeight = window.innerHeight;
        if (props.top + menuRect.height > viewportHeight) {
          Y.value = viewportHeight - menuRect.height;
        } else {
          Y.value = props.top;
        }
        const viewportWidth = window.innerWidth;

        if (props.left + menuRect.width > viewportWidth) {
          X.value = viewportWidth - menuRect.width;
        } else {
          X.value = props.left;
        }
      }
    }
  }
});
</script>

<template>
  <div class="context-menu" ref="context" v-if="display" tabindex="0" @blur="close()">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.context-menu {
  position: absolute;
  top: 2.2em;
  right: 2.2em;
  width: fit-content;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: fixed;
  background: white;
  z-index: 7;
  box-shadow: 0 .3em .3em rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-top-left-radius: .6em;
  border-bottom-left-radius: .6em;
  border-bottom-right-radius: .6em;
}
</style>
