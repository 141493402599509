<script lang="ts" setup>
import { onMounted, PropType, ref } from "vue";
import { getDaysMonth, taskList } from "./helpers";
import { useStore } from "@/entities";

const props = defineProps({
  type: {
    type: String as PropType<"task" | "pick">,
    required: false,
    default: () => "task",
  },
});
const emits = defineEmits(["picked", "close"]);

const store = useStore();

const date = new Date();
const currentMoth = ref(date.getMonth());
const currentYear = ref(date.getFullYear());
const currentDayOfWeek = ref(date.getDay() === 0 ? 6 : date.getDay());
const year = ref(date.getFullYear());
const month = ref(date.getMonth());
const currentWeek = ref(true);
const selectedDay = ref(new Date().getDate());
const startYear = ref(2020);

const tasksMap = ref<Map<string, { task: iStore.Task[] }>>(new Map());
const dayOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
const state = ref("task");

function checkExistTask(date: string) {
  return tasksMap.value.has(date);
}

const days = ref<{
  weeks: (number | boolean)[][];
  mount: string;
}>({ weeks: [], mount: "" });

function incementmonth(val: number) {
  month.value = month.value + val;
  if (val > 0 && month.value > 11) {
    month.value = 0;
    year.value++;
  } else if (val < 0 && month.value < 0) {
    month.value = 11;
    year.value--;
  }
  currentWeek.value = currentMoth.value === month.value;
  days.value = getDaysMonth(month.value, year.value);
  store.dispatch("tasks/GET_TASKS_OF_MONTH", { month: month.value + 1, year: year.value }).then((tasks) => {
    tasksMap.value = taskList(tasks);
  });
}

function selectDay(date: string) {
  const payload = tasksMap.value.has(date);
  const day = new Date(date).getDate();
  selectedDay.value = day;
  if (props.type === "pick") {
    emits("picked", { year: year.value, month: (month.value + 1).toString().padStart(2, "0"), day: day.toString().padStart(2, "0") });
  } else {
    if (payload) return store.commit("tasks/SET_TASKS_OF_SELECTED_DAY", { day: date, tasks: tasksMap.value.get(date)?.task });
    store.commit("tasks/SET_TASKS_OF_SELECTED_DAY", { day: date, tasks: [] });
  }
}

days.value = getDaysMonth(month.value, year.value);

function setMonth(number: number) {
  month.value = number;
  state.value = "task";
}

function setYear(number: number) {
  year.value = number;
  state.value = "task";
}

onMounted(() => {
  if (props.type === "task") {
    store.dispatch("tasks/GET_TASKS_OF_MONTH", { month: month.value + 1, year: year.value }).then((tasks) => {
      tasksMap.value = taskList(tasks);
      selectDay(`${year.value}-${String(month.value + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`);
    });
  }
});
</script>

<template>
  <div class="calendar" :class="{ 'dynamic-calendar': type === 'pick' }">
    <div class="calendar__control">
      <input type="button" @click="incementmonth(-1)" />
      <div>
        <h2 @click="state === 'month' ? (state = 'task') : (state = 'month')">{{ months[month] }}</h2>
        <h2 @click="state === 'year' ? (state = 'task') : (state = 'year')">{{ year }}</h2>
      </div>
      <input type="button" @click="incementmonth(1)" />
    </div>
    <div class="calendar-weeks-days" v-if="state === 'task'">
      <p
        v-for="(day, index) in dayOfWeek"
        :key="index"
        :class="{
          'current-weekday': index === currentDayOfWeek - 1 && currentWeek && currentYear === year,
          'week-end': day === 'СБ' || day === 'ВС',
        }"
      >
        {{ day }}
      </p>
    </div>
    <div class="calendar-weeks__wrp" v-if="state === 'task'">
      <div v-for="(week, index) in days.weeks" class="calendar-weeks" :key="index">
        <div
          v-for="(day, i) in week"
          :key="i"
          :class="{
            withTask: type !== 'pick' && day && checkExistTask(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`),
            noneTask: (type !== 'pick' && !day) || !checkExistTask(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`),
          }"
        >
          <p
            :class="{
              'current-day': type === 'task' && day === new Date().getDate() && currentMoth === month && currentYear === year,
              'calendar-weeks__day': day,
              'selected-day': day === selectedDay && currentMoth === month && currentYear === year,
            }"
            @click="day && selectDay(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`)"
          >
            {{ day ? day : "" }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="state === 'month'" class="calendar-months">
      <div>
        <p
          v-for="(m, i) in months.slice(0, 4)"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': currentMoth === i && currentYear === year,
          }"
          @click="setMonth(i)"
        >
          {{ m.slice(0, 3).toUpperCase() }}
        </p>
      </div>
      <div>
        <p
          v-for="(m, i) in months.slice(4, 8)"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': currentMoth === i + 4 && currentYear === year,
          }"
          @click="setMonth(i + 4)"
        >
          {{ m.slice(0, 3).toUpperCase() }}
        </p>
      </div>
      <div>
        <p
          v-for="(m, i) in months.slice(8)"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': currentMoth === i + 8 && currentYear === year,
          }"
          @click="setMonth(i + 8)"
        >
          {{ m.slice(0, 3).toUpperCase() }}
        </p>
      </div>
    </div>
    <div v-if="state === 'year'" class="calendar-year">
      <div>
        <p
          v-for="(y, i) in 5"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': startYear + y === currentYear,
          }"
          @click="setYear(startYear + y)"
        >
          {{ startYear + y }}
        </p>
      </div>
      <div>
        <p
          v-for="(y, i) in 5"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': startYear + y + 5 === currentYear,
          }"
          @click="setYear(startYear + y + 5)"
        >
          {{ startYear + y + 5 }}
        </p>
      </div>
      <div>
        <p
          v-for="(y, i) in 5"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': startYear + y + 10 === currentYear,
          }"
          @click="setYear(startYear + y + 10)"
        >
          {{ startYear + y + 10 }}
        </p>
      </div>
      <div>
        <p
          v-for="(y, i) in 5"
          :key="i"
          class="calendar-weeks__day"
          :class="{
            'current-month': startYear + y + 15 === currentYear,
          }"
          @click="setYear(startYear + y + 15)"
        >
          {{ startYear + y + 15 }}
        </p>
      </div>
    </div>
    <div v-if="type === 'pick'" class="dynamic-calendar__button">
      <button @click="$emit('close')">Закрыть</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar {
  width: calc(100% - 1.4em);
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-background-color);
  gap: 0.4em;
  border-radius: 0.4em;
}

.dynamic-calendar {
  width: 16.6vw;
  position: absolute !important;
  top: 3em;
  right: 0;
  transform: translate(50%);
  box-shadow: 0 0.05em 0.2em #363333;
  z-index: 7;
}
.dynamic-calendar__button {
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center !important;
  & button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    padding: 0.3em 0.6em;
    border-radius: 0.6em;
  }
  & button:nth-child(1) {
    background-color: var(--third-background-color);
    &:hover {
      background-color: var(--fourth-hover-color);
    }
  }
  & button:nth-child(2) {
    background-color: var(--primary-color);
    &:hover {
      background-color: var(--primary-hover-color);
    }
  }
}

.calendar__control {
  width: calc(100% - 1.4em);
  display: flex;
  justify-content: space-between;

  & div {
    display: flex;
    gap: 0.6em;
  }

  & h2 {
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    color: var(--secondary-text-color);
    user-select: none;
    cursor: pointer;
  }

  & input {
    width: 2em;
    background: none;
    outline: none;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    padding-block: 0;
    padding-inline: 0;
    cursor: pointer;
  }

  & input:nth-child(1) {
    background-image: url("@/share/assets/icons/arrowLeftIcon.svg");
  }

  & input:nth-child(3) {
    background-image: url("@/share/assets/icons/arrowRightIcon.svg");
  }
}

.calendar-weeks {
  display: flex;
  gap: 0.5em;
  color: var(--secondary-text-color);

  & p {
    width: 1.2em;
    aspect-ratio: 1/1;
    margin: 0;
    padding: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
    line-height: 0.8;
    border: 0.1em solid transparent;
  }
}

.calendar-weeks-days {
  @extend .calendar-weeks;
}

.calendar-months {
  margin: 1.66em 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  & div {
    display: flex;
    gap: 1em;
    color: var(--secondary-text-color);
    & p {
      width: 1.2em;
      aspect-ratio: 1/1;
      margin: 0;
      padding: 1.55em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      user-select: none;
      line-height: 0.8;
      border: 0.1em solid transparent;
    }
  }
}

.calendar-year {
  margin: 1.3em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  & div {
    display: flex;
    gap: 0.5em;
    color: var(--secondary-text-color);
    & p {
      width: 0.8em;
      aspect-ratio: 1/1;
      margin: 0;
      padding: 1.405em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      user-select: none;
      line-height: 0.8;
      border: 0.1em solid transparent;
    }
  }
}

.current-month {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
  border: 0.1em solid var(--primary-color) !important;
}

.calendar-weeks__wrp {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.calendar-weeks__day {
  background-color: var(--background-item-color);
  cursor: pointer;
  transition: transform 0.2s ease;
  user-select: none;

  &:active {
    transform: scale(0.8);
  }
}

.calendar-weeks__day:hover {
  background-color: var(--background-item-hover-color);
}

.current-day {
  background-color: var(--secondary-background-color);
  color: var(--primary-color);
  border: 0.1em solid var(--primary-color) !important;
}

.week-end {
  color: var(--weekend-color);
}

.current-weekday.week-end {
  color: var(--text-color);
}

.withTask {
  position: relative;
}

.withTask::before {
  content: "";
  width: 0.3em;
  aspect-ratio: 1/1;
  border-radius: 100%;
  position: absolute;
  background: var(--primary-color);
  bottom: 0.3em;
  left: 50%;
  transform: translate(-50%, 0);
}

.noneTask {
  position: relative;
}

.noneTask::before {
  content: "";
  width: 0.3em;
  aspect-ratio: 1/1;
  border-radius: 100%;
  position: absolute;
  background: transparent !important;
  bottom: 0.3em;
  left: 50%;
  transform: translate(-50%, 0);
}

.selected-day {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.selected-month {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}

@media screen and (max-width: 480px) {
  .calendar {
    padding: 0.5em;
  }

  .calendar__control {
    & h2 {
      font-size: 1em;
    }

    & input {
      width: 1.5em;
    }
  }

  .calendar-weeks {
    & p {
      width: 1em;
      font-size: 0.9em;
    }
  }
}
</style>
