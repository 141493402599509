export function capitalLetter(e: Event) {
  const event = (e as unknown as InputEvent)
  const input = e.target as HTMLInputElement;
  if (event.inputType === 'insertText') {

  }
  if (/^\s/.test(input.value)) input.value.replace(/^\s/, "")
  if (/^([а-яa-z])/.test(input.value)) {
    input.value = input.value.replace(/^([а-яa-z])/, (ags) => ags.toUpperCase())
  }
}

export function capitalFirstLetter(e: Event) {
  const input = e.target as HTMLInputElement;
  return (input.value = input.value.charAt(0).toUpperCase() + input.value.slice(1))
}