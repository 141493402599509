import { Socket } from "socket.io-client";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { Store } from "vuex";

export function socketEventHandler(sockets: Socket, store: Store<iStore.RootState>, route: RouteLocationNormalizedLoaded) {
  sockets.on("disconnect", () => {
    store.commit("user/SET_DISCONNECTED")
  })

  sockets.on("connect", () => {
    store.commit("user/SET_CONNECTED")
  })

  sockets.io.on("reconnect_attempt", () => {
    store.commit("user/INCREMENT_ATTEMPT")
  })

  sockets.io.on("reconnect_failed", () => {
    store.commit("user/FAIL_RECONNECT")
  })

  sockets.on("new_event", (payload: { link: string, message: string, personalId: number, typeId: number }) => {
    store.commit("events/PUSH_EVENT", payload)
  })

  sockets.on("new_event_emit", (payload: { link: string, message: string, personalId: number, typeId: number }) => {
    store.commit("events/SET_NOTIFICATIONS", payload)
  })

  sockets.on("task_event", (payload: { type: string, notification: iStore.iNotifications, task: iStore.Task }) => {
    store.commit("events/SET_NOTIFICATIONS", payload.notification)
    store.commit("tasks/TASK_EVENT", { task: payload.task, type: payload.type })
  })

  sockets.on("notification", (payload: { link: string | null, action: "TaskInfo" | "Request" | null, message: string, personalId: 1, typeId: 4 }) => {
    store.commit("events/SET_NOTIFICATIONS", payload)
  })

  sockets.on("setMessages", (payload) => {
    store.commit("chat/SET_MESSAGES", payload);
  });

  sockets.on("emit_message", (payload) => {
    store.commit("chat/SET_MESSAGE", payload);
  });

  sockets.on("delete_message", (payload: { messageId: number, roomName: string }) => {
    store.commit("chat/DELETE_MESSAGE", payload)
    store.commit("titles/CLOSE_MODAL", payload)
  })

  sockets.on("edit_message", (msg) => {
    store.commit("chat/SET_EDITED_MESSAGE", msg)
  })

  sockets.on("typing", (payload: { orgId: number, personalId: number }) => {
    store.commit("chat/TYPINGS", payload)
    store.dispatch("chat/CHECK_TYPINGS")
  })

  sockets.on("setReadedMessage", (payload) => {
    store.commit("chat/SET_MESSAGE_TO_READED", payload);
  });

  sockets.on("setOnline", (payload) => {
    store.commit("chat/SET_ONLINE", payload);
  });

  sockets.on("setOffline", (payload) => {
    store.commit("chat/SET_OFFLINE", payload);
  });

  sockets.on("stucture", (payload: { eventName: "record_event" | "contacts_event" | "department_event" | "personal_event" | "position_event" | "organization_event" | "roles_event" | "organizations_event", type: "updated" | "created" | "deleted", data: any }) => {
    if (payload.eventName === "organizations_event") {
      store.commit("personal/SET_ORGANIZATIONS", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "department_event") {
      store.commit("personal/SET_DEPARTMENT", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "personal_event") {
      store.commit("personal/SET_PERSON", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "contacts_event") {
      store.commit("personal/SET_PERSON_CONTACTS", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "position_event") {
      store.commit("personal/SET_POSITION", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "record_event") {
      store.commit("personal/SET_PERSON_RECORD", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "roles_event") {
      store.commit("personal/SET_PERSON_ROLES", { type: payload.type, data: payload.data })
    } else if (payload.eventName === "organization_event") {
      store.commit("personal/SET_ORGANIZATION", { data: payload.data })
    }
  })

  sockets.on("task_message", (payload: { taskId: number, files: [], message: string, senderId: number, createdAt: string, typeId: number }) => {
    if (route.fullPath === `/requests/info/${payload.taskId}?state=chat`) {
      store.commit("tasks/PUSH_TASK_MESSAGE", payload)
      store.commit("titles/CLOSE_MODAL", payload)
    }
  })

  sockets.on("delete_task_message", (payload: { messageId: number, taskId: number }) => {
    if (route.fullPath === `/requests/info/${payload.taskId}?state=chat`) {
      store.commit("tasks/DELETE_MESSAGE", payload.messageId)
      store.commit("titles/CLOSE_MODAL", payload)
    }
  })
}