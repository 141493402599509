<script setup lang="ts">
import { PropType, ref } from "vue";

const emit = defineEmits(["inputVal"]);
const modelVal = ref("");

// Define props
const props = defineProps({
  readonly: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  textVal: {
    type: String as PropType<string>,
    required: false,
    default: "",
  },
  rowsCount: {
    type: Number as PropType<number>,
    required: false,
    default: 1,
  },
});

function updateValue(e: Event) {
  const newValue = (e.target as HTMLInputElement).value;
  modelVal.value = newValue;
  emit("inputVal", newValue);
}
</script>

<template>
  <textarea :class="{ readonly: props.readonly }" rows="4" :value="props.textVal" @input="updateValue" :readonly="props.readonly"></textarea>
</template>

<style scoped lang="scss">
textarea {
  justify-self: auto;
  padding: 0.5em;
  border: 0.1em solid var(--fourth-border-color);
  outline: none;
  border-radius: 0.4em;
  word-wrap: break-word !important;
  background-color: var(--secondary-background-color);
}

.readonly {
  cursor: default !important;
  border: 0.1em solid transparent !important;
  background-color: transparent !important;
}
</style>
