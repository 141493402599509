<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, ref, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

const context = ref();
const store = useStore();
const emit = defineEmits(["close"]);
const router = useRouter()

const notificationMessages = computed(() => store.state.events.notifications.filter(n => !n.readed));

function setReaded(id: number): Promise<void> {
  return new Promise((resolve) => {
    doRequest("/notifications/readed?id=" + id, { method: "GET" })
      .then(() => {
        store.commit("events/SET_READED", id)
        resolve()
      })
  })
}

async function setAllReaded() {
  for await (const not of notificationMessages.value) await setReaded(not.id)
  checkLength()
}

function checkLength() {
  if (!notificationMessages.value.length) emit('close')
}

function goNotifications() {
  emit("close");
  router.push({ name: 'Notifications' })
}

function setModal(notification: iStore.iNotifications) {
  if (notification.link) setReaded(notification.id)
  emit("close");
  store.commit('events/SET_REQUEST', notification)
  store.commit('titles/SET_MODAL', 'request')
}

function goToTask(notification: iStore.iNotifications) {
  setReaded(notification.id)
  emit("close");
  router.push({ name: 'TaskInfo', params: { id: notification.link }, query: { state: "info" } })
}

function handleEscapeKey(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    emit('close');
  }
}

function handleClickOutside(event: MouseEvent) {
  if (context.value && !context.value.contains(event.target as Node)) {
    emit('close');
  }
}

function resolveAction(notification: iStore.iNotifications) {
  switch (notification.action) {
    case null: {
      setReaded(notification.id)
      break
    }
    case "Request": {
      setModal(notification)
      break
    }
    case "TaskInfo": {
      goToTask(notification)
      break
    }
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscapeKey);
  document.addEventListener('mousedown', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscapeKey);
  document.removeEventListener('mousedown', handleClickOutside);
});

watchEffect(() => {
  if (context.value) {
    context.value.focus();
  }
});
</script>


<template>
  <div class="notification" ref="context" tabindex="0">
    <div class="notification__header">
      <button type="button" @click="goNotifications">Все уведомления</button>
      <button type="button" class="clean-button" @click="setAllReaded">Очистить</button>
    </div>
    <div class="notification__wrp">
      <div v-if="notificationMessages.length" class="notification__message" v-for="(notification, index) in notificationMessages" :key="index" @click="resolveAction(notification)">
        <p class="notification__message-type">{{ notification.type.name }}</p>
        <p v-if="notification.action !== null"
          class="notification__message-link"
        >{{ notification.message }}</p>
        <p v-else :style="{ cursor: 'pointer' }">{{ notification.message }}</p>
        <p class="notification__message-date">{{ new Date(notification.createdAt).toLocaleString("ru", { timeStyle: "short", dateStyle: "short" }) }}</p>
      </div>
      <div v-else class="notification__empty">
        <p>Нет новых уведомлений</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.notification {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: absolute;
  top: 4.5em;
  right: 9em;
  background: white;
  z-index: 5;
  border-radius: 0.4em;
  border-top-right-radius: 0;
  width: 20em;
  box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.29);

  & p {
    margin: 0;
  }

  &__empty {
    text-align: center;
    font-weight: bold;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & button {
      display: flex;
      align-items: center;
      gap: 1em;
      padding: .3em;
      border: none;
      outline: none;
      color: var(--primary-color);
      border-radius: 0.4em;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    cursor: pointer !important;
    gap: 1em;

    &-type,
    &-date {
      align-self: center;
      font-weight: bold;
    }

    &-date {
      font-size: .8em;
      font-weight: normal;
    }

    &-link {
      text-decoration: underline;
    }
  }
}

.notification__wrp {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;


  &::-webkit-scrollbar {
    width: 0.2em;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--background-color);
  }
}

.clean-button {
  cursor: pointer !important;
  color: var(--third-text-color);
  transition: transform 0.2s ease;

  &:active {
    transform: scale(0.95);
  }
}
</style>
