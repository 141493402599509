<script setup lang="ts">
import { useStore } from "@/entities";
import { sockets } from "@/entities";
import { PropType, computed, onMounted, onUpdated, ref, watch } from "vue";
import { FileUploader } from "@/features";
import { contextMenu } from "@/share/UI";

const store = useStore();
const fileUploadedModal = ref(true);
const selectedMessage = ref<iStore.SocketsMessages>();
const actionType = ref<"delete" | "edit">();
const wrp = ref<HTMLDivElement>();

const count = 112;
const message = ref<Omit<iStore.SocketsMessages, "delivered" | "senderName" | "hasReaded" | "senderId" | "updatedAt" | "createdAt">>({
  files: [],
  message: ``,
  edited: false,
  comment: "",
  readed: false,
  roomName: "",
});
const rows = ref(message.value.message.length / count > 10 ? 10 : Math.floor(message.value.message.length / count));
const topContext = ref(0);
const leftContext = ref(0);
const showContext = ref(false);
const selected = computed(() => store.state.chat.selectedRoom);
const scrolledWrp = ref(false);

const props = defineProps({
  mobile: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  list: {
    type: Array as PropType<iChat.Person[]>,
    required: true,
  },
});

let expireInterval = false;

function emitTyping() {
  if (expireInterval || actionType.value === "edit") return;
  if (!expireInterval) expireInterval = true;
  setTimeout(() => {
    expireInterval = false;
  }, 2000);
  sockets.emit("text_typing", {
    orgId: props.list.find((p) => p.roomName === selected.value)?.orgId,
    roomName: props.list.find((p) => p.roomName === selected.value)?.roomName,
    personalId: selectedPers.value?.personalId,
  });
}

const isPersNullText = computed(() => {
  if (props.list.length > 0) {
    return "Выберите чат";
  } else return "Для начала общения, пожалуйста, зарегистрируйте других пользователей в системе";
});

const selectedPers = computed(() => {
  if (!wrp.value) {
    let interval = setInterval(() => {
      if (wrp.value) {
        wrp.value.scrollTo({ behavior: "instant", top: wrp.value.scrollHeight });
        setTimeout(() => {
          wrp.value!.classList.remove("hide-for-load");
          clearInterval(interval);
        }, 50);
      }
    }, 1);
  } else {
    if (wrp.value) {
      wrp.value.scrollTo({ behavior: "instant", top: wrp.value.scrollHeight });
      setTimeout(() => {
        wrp.value!.classList.remove("hide-for-load");
      }, 50);
    }
  }
  const pers = props.list.find((pers) => pers.roomName === selected.value);
  if (!pers) return undefined;
  // store.commit("titles/SET_TITLE", pers.shortName);
  return pers;
});

const messages = computed(() => store.state.chat.messages);

function sendMessages() {
  fileUploadedModal.value = false;
  const resiver = props.list.find((pers) => pers.roomName === selected.value);
  if (!resiver || !selectedPers.value) return;
  if (selected.value === undefined || selected.value === null) return;
  if (!message.value.message && !message.value.files.length) return;
  if (actionType.value === "edit") message.value.id = selectedMessage.value?.id;
  message.value.files;
  sockets.emit("sendMessage", { roomName: selectedPers.value.roomName, message: message.value });
  message.value.edited = false;
  actionType.value = undefined;
  message.value.message = "";
  message.value.files = [];
  rows.value = 1;
}

function renderMessage(msg: iStore.SocketsMessages) {
  if (msg.hasReaded && msg.hasReaded.findIndex((i) => i === store.state.personal.id) === -1) sockets.emit("setReadedMessage", { messageId: msg.id });
  if (msg.readed === false && msg.senderId !== store.state.chat.selfId) sockets.emit("setReadedMessage", { messageId: msg.id });
  return msg.message;
}

const getShortName = (id: number) => {
  if (!props.list) return "";
  const index = props.list.findLastIndex((p) => p.personalId === id);
  if (index !== -1) return props.list[index].shortName;
  return "";
};

function getParsedDateString(date: string) {
  if (new Date(date).toLocaleDateString() === new Date().toLocaleDateString()) return "Сегодня";
  else return new Date(date).toLocaleDateString("ru", { day: "2-digit", month: "long" });
}

function getDateString(date: string) {
  const str = new Date(new Date(date)).toLocaleDateString().split(".").reverse().join("-");
  return Date.parse(str);
}

function getDate(index: number) {
  if (index < 1) return false;
  const prev = getDateString(messages.value[index - 1].createdAt);
  const current = getDateString(messages.value[index].createdAt);
  if (current - prev > 0) return true;
}

function humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
}

function contextOpen(e: MouseEvent, message: iStore.SocketsMessages) {
  e.preventDefault();
  if (message.senderId !== store.state.user?.info.id) return;
  selectedMessage.value = message;
  topContext.value = e.pageY || e.clientY;
  leftContext.value = e.pageX || e.clientX;
  if (window.screen.availWidth - leftContext.value < 200) {
    leftContext.value = leftContext.value - 170;
  }
  showContext.value = true;
}

function contextClose(e: MouseEvent) {
  topContext.value = 0;
  leftContext.value = 0;
  showContext.value = false;
}

function downloadFile(url: string, filename: string) {
  fetch(`${process.env.VUE_APP_API_URL}/v1${url}`, { method: "GET", credentials: "include" })
    .then((resp) => resp.blob())
    .then((blob) => {
      const link = document.createElement("a");
      document.body.appendChild(link);
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 0);
    });
}

function scrolled(e: Event) {
  const { target } = e as Event & { target: HTMLDivElement };
  scrolledWrp.value = (target.scrollHeight - target.scrollTop) / target.clientHeight === 1;
}

onUpdated(() => {
  if (wrp.value && scrolledWrp.value) {
    wrp.value.scrollTo({ behavior: "instant", top: wrp.value.scrollHeight });
  }
});

watch(selected, () => {
  message.value.message = "";
  message.value.files = [];
  if (wrp.value && scrolledWrp.value) {
    wrp.value.scrollTo({ behavior: "instant", top: wrp.value.scrollHeight });
  }
});

watch(message.value, (val) => {
  if (val.message) {
    rows.value = 1;
    const matches = [...val.message.matchAll(/\n/g)];
    if (matches.length > 0) rows.value += matches.length;
    const result = rows.value + Math.floor(val.message.length / count);
    rows.value = result > 10 ? 10 : result;
    if (wrp.value) {
      const currentHeight = wrp.value.clientHeight;
      wrp.value.style.height = `${currentHeight - 16.5 * (rows.value + 2)}px`;
    }
  } else {
    rows.value = 1;
  }
});

function editMessage() {
  if (!selectedMessage.value) return;
  actionType.value = "edit";
  message.value.edited = true;
  message.value.message = selectedMessage.value.message;
  showContext.value = false;
}

function cancelEdit() {
  selectedMessage.value = undefined;
  actionType.value = undefined;
  message.value.edited = false;
  message.value.message = "";
  rows.value = 1;
}

function deleteMessage() {
  if (!selectedMessage.value) return;
  showContext.value = false;
  store.commit("chat/SET_MESSAGE_ID", selectedMessage.value.id);
  store.commit("titles/SET_MODAL", "delete-message");
}

function getGender(str: string) {
  return /(a|а)$/.test(str.trim());
}

function sendFiles(msg: { files: { uid: string; size: number; filename: string }[]; comment: string }) {
  if (selectedPers.value) {
    sockets.emit("sendMessage", {
      roomName: selectedPers.value.roomName,
      message: {
        message: message.value.message || "",
        files: msg.files,
        comment: msg.comment,
        edited: false,
        readed: false,
      },
    });
  }
}

function checkEdited(msg: iStore.SocketsMessages) {
  if (!msg.edited) return new Date(msg.createdAt).toLocaleTimeString("ru", { timeStyle: "short" });
  else return `Изменено ${new Date(msg.updatedAt).toLocaleTimeString("ru", { timeStyle: "short" })}`;
}

onMounted(() => {
  window.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key === "Enter") {
      sendMessages();
    }
  });
  if (wrp.value && scrolledWrp.value) {
    wrp.value.scrollTo({ behavior: "instant", top: wrp.value.scrollHeight });
  }
});
</script>
<template>
  <div class="messenger messenger__center" v-if="selected === undefined || selected === null">
    <img :src="require(`@/share/assets/icons/messagesIcon.svg`)" />
    <p>{{ isPersNullText }}</p>
  </div>
  <div class="messenger" v-else>
    <div class="messenger__resiver" v-if="selectedPers && !mobile">
      <div class="messenger__resiver__avatar">
        <img v-if="selectedPers.user?.avatar" class="messenger__resiver__avatar__image" :src="selectedPers.user?.avatar" />
        <p v-else class="messenger__resiver__avatar__image" :class="selectedPers.class">
          {{ selectedPers.abbreviation }}
        </p>
      </div>
      <div class="member__name">
        <p>{{ selectedPers.fullName }}</p>
        <p>
          {{
            selectedPers.online
              ? "В сети"
              : selectedPers.lastSeen.length
              ? getGender(selectedPers.fullName)
                ? "Была в сети " + new Date(selectedPers.lastSeen).toLocaleString("ru", { dateStyle: "short", timeStyle: "short" }).split(",").join(" ")
                : "Был в сети " + new Date(selectedPers.lastSeen).toLocaleString("ru", { dateStyle: "short", timeStyle: "short" }).split(",").join(" ")
              : "Вне сети"
          }}
        </p>
      </div>
    </div>
    <div class="messenger__resiver" v-else-if="!mobile">
      <p>&nbsp;</p>
    </div>
    <div class="messenger__messages__wrp hide-for-load" ref="wrp" @scroll="scrolled">
      <div class="messenger__messages">
        <div class="messages" v-for="(msg, index) in messages" :key="index" :class="msg.senderId === $store.state.user?.info.id ? 'messenger__messages__own' : ''">
          <p v-if="getDate(index) || index === 0" class="messenger__messages__date">{{ getParsedDateString(msg.createdAt) }}</p>
          <div class="message-content" v-if="msg.message.length" @click.right.prevent="(e) => contextOpen(e, msg)">
            <div
              v-if="selectedPers && !/private/.test(selected) && msg.senderId !== $store.state.user?.info.id"
              style="display: flex; flex-direction: row; gap: 1em"
              class="public-msg"
            >
              <div class="public-msg-sender" style="display: flex; align-self: center">
                <img v-if="selectedPers.user?.avatar" class="public-msg-sender__image" :src="selectedPers.user?.avatar" />
                <p v-else class="public-msg-sender__image" :class="selectedPers.class">
                  {{ selectedPers.abbreviation }}
                </p>
              </div>
              <div>
                <p style="font-weight: bold">{{ getShortName(msg.senderId) }}</p>
                <p :class="{ 'deleted-message': msg.deleted }" :style="{ display: msg.message.length ? 'block' : 'none' }">{{ renderMessage(msg) }}</p>
                <span
                  :class="{
                    msgDelivered: msg.delivered && !msg.readed && msg.senderId === $store.state.user?.info.id,
                    msgReaded: msg.readed && msg.senderId === $store.state.user?.info.id,
                  }"
                  >{{ checkEdited(msg) }}</span
                >
              </div>
            </div>
            <div v-else>
              <p :class="{ 'deleted-message': msg.deleted }" v-if="msg.message.length">{{ renderMessage(msg) }}</p>
              <span
                :class="{
                  msgDelivered: msg.delivered && !msg.readed && msg.senderId === $store.state.user?.info.id,
                  msgReaded: msg.readed && msg.senderId === $store.state.user?.info.id,
                }"
                >{{ checkEdited(msg) }}</span
              >
            </div>
          </div>
          <div v-if="msg.files.length > 0" class="files-wrp">
            <div
              v-for="(link, i) in msg.files"
              :key="i"
              @click.right="(e) => contextOpen(e, msg)"
              :class="{ self: msg.senderId === $store.state.chat.selfId, member: msg.senderId !== $store.state.chat.selfId }"
            >
              <input type="button" @click="downloadFile(`${link.url}/${link.uid}`, link.filename)" />
              <div
                :class="{
                  fileDelivered: msg.delivered && !msg.readed && msg.senderId === $store.state.user?.info.id,
                  fileReaded: msg.readed && msg.senderId === $store.state.user?.info.id,
                }"
              >
                <p>{{ link.filename.split(".")[0] }}</p>
                <p>{{ link.filename.split(".")[link.filename.split(".").length - 1].toUpperCase() }}, {{ humanFileSize(link.size) }}</p>
              </div>
            </div>
            <p>{{ msg.comment }}</p>
            <span
              :class="{
                msgDelivered: msg.delivered && !msg.readed && msg.senderId === $store.state.user?.info.id,
                msgReaded: msg.readed && msg.senderId === $store.state.user?.info.id,
              }"
            >
              {{ new Date(msg.createdAt).toLocaleTimeString("ru", { timeStyle: "short" }) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="messenger__actions">
      <div class="messenger__edit" v-if="actionType === 'edit'">
        <div>
          <svg viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24.1229 0.186349C23.7094 -0.122952 23.1235 -0.0385053 22.8142 0.374965L22.4782 0.824158C22.3545 0.989547 22.3882 1.22392 22.5536 1.34764L23.452 2.01968C23.6174 2.1434 23.8518 2.10962 23.9755 1.94423L24.3115 1.49504C24.6208 1.08157 24.5364 0.495649 24.1229 0.186349Z"
              fill="#979DA0"
            />
            <path
              d="M21.9194 1.57289C22.0432 1.4075 22.2775 1.37372 22.4429 1.49744L23.3413 2.16949C23.5067 2.29321 23.5405 2.52758 23.4168 2.69296L12.28 17.5805C12.1739 17.7224 12.0481 17.8485 11.9065 17.9549L10.9884 18.6453C10.5401 18.9824 9.92916 18.5254 10.126 18.0001L10.529 16.9245C10.5911 16.7586 10.6766 16.6023 10.7827 16.4604L21.9194 1.57289Z"
              fill="#979DA0"
            />
            <path
              d="M2.34595 4.30105C2.24268 4.30105 2.15896 4.38477 2.15896 4.48804V20.9431C2.15896 21.0464 2.24268 21.1301 2.34595 21.1301H18.801C18.9043 21.1301 18.988 21.0464 18.988 20.9431V11.4066C18.988 10.8903 19.4066 10.4717 19.923 10.4717C20.4393 10.4717 20.8579 10.8903 20.8579 11.4066V20.9431C20.8579 22.0791 19.937 23 18.801 23H2.34595C1.20996 23 0.289062 22.0791 0.289062 20.9431V4.48804C0.289062 3.35205 1.20996 2.43115 2.34595 2.43115H18.0531C18.5694 2.43115 18.988 2.84974 18.988 3.3661C18.988 3.88246 18.5694 4.30105 18.0531 4.30105H2.34595Z"
              fill="#979DA0"
            />
          </svg>
        </div>
        <div>
          <p>Редактирование</p>
          <p>{{ message.message }}</p>
        </div>
        <div>
          <button @click="cancelEdit"></button>
        </div>
      </div>
      <form class="messenger__actions__form" @submit.prevent="sendMessages">
        <FileUploader :destination="'chat'" @sendMessage="sendFiles" :showAddText="false" />
        <textarea placeholder="Написать сообщение" v-model="message.message" @input.prevent="emitTyping" :rows="rows || 1"></textarea>
        <input type="submit" value="" :class="{ 'edit-button': actionType === 'edit' }" />
      </form>
    </div>
  </div>
  <contextMenu class="contextMenu" :display="showContext" :top="topContext" :left="leftContext" @close="contextClose">
    <div @click="editMessage">
      <img :src="require(`@/share/assets/icons/editPencilIcon.svg`)" />
      <p>Редактировать</p>
    </div>
    <div @click="deleteMessage">
      <img :src="require(`@/share/assets/icons/deleteIcon.svg`)" />
      <p>Удалить</p>
    </div>
  </contextMenu>
</template>
<style lang="scss" scoped>
@media screen and (max-width: 480px) {
  .messenger__messages {
    padding-bottom: 3.5em !important;
    width: calc(100vw - 2em);
  }

  .messenger__actions {
    width: 100%;
    position: fixed;
    bottom: 0em;
    background-color: var(--secondary-background-color);

    &__form {
      padding: 0 0.5em;

      & label {
        width: 1.5em;
      }
    }
  }

  .messenger__messages__date {
    margin: 0;
    padding: 0.5em 0.5em;
    font-size: 0.9em;
    border-radius: 10em;
  }
}

@media screen and (min-width: 480px) {
  .messenger__messages__wrp {
    max-height: calc(100vh - 15em);
  }

  .messenger__actions {
    &__form {
      padding: 1em;

      & label {
        width: 1.5em;
      }
    }
  }

  .messenger__messages__date {
    padding: 0.5em 1em;
    font-size: 1.2em;
    border-radius: 10em;
  }
}

.hide-for-load {
  opacity: 0;
  background-color: var(--background-color);
}

.messenger__actions__form {
  position: relative;
}

.messenger {
  height: 100%;
  flex-grow: 1;
  flex-basis: calc(70% - 1.5em);
  display: flex;
  flex-direction: column;
  // gap: 1em;
  border-left: 0.1em solid var(--fourth-border-color);
}

.messenger__resiver {
  padding: 1em;
  display: flex;
  gap: 1em;

  & p {
    margin: 0;
  }
}

.public-msg {
  & > div {
    padding: 0 !important;
  }

  &-sender {
    padding: 0 !important;

    & p,
    img {
      display: flex;
      margin: 0;
      padding: 0;
      padding: 0.4em;
      width: 2em;
      aspect-ratio: 1/1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      background-color: #707070;
      border-radius: 50%;
      color: var(--text-color);
    }
  }
}

.messenger__messages__wrp {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--background-color);
  }
}

.messenger__messages {
  background-color: var(--background-color);
  position: relative;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  padding: 1em;

  & .messages {
    width: 100%;
    gap: 1em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    border-radius: 0.7em;
    position: relative;
    flex-wrap: wrap;
    word-break: break-word;

    & .message-content {
      max-width: 60%;
      display: flex;
      gap: 0.5em;
      flex-direction: column;

      & div {
        display: flex;
        gap: 0.2em;
        flex-direction: column;
        background-color: var(--secondary-background-color);
        padding: 1em 1.5em;
        border-radius: 0.7em;
        align-self: baseline;

        & p {
          margin: 0;
        }

        & span {
          display: inline-block;
          align-self: flex-end;
          color: var(--fourth-text-color) !important;
          font-size: 0.7em;
        }
      }
    }
  }
}

.member__name {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  word-wrap: break-word;

  & > p:nth-child(1) {
    font-weight: bold;
    color: var(--secondary-text-color);
  }

  & > p:nth-child(2) {
    color: var(--third-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.messenger__messages__date {
  text-align: center;
  background-color: var(--button-background-button);
  align-self: center;
  color: var(--text-color);
}

.messenger__messages__own {
  align-self: flex-end !important;

  & div {
    align-self: flex-end !important;

    & p {
      align-self: flex-end !important;
      color: var(--secondary-text-color) !important;
    }

    & span {
      text-align: end;
      padding: 0 1em 0 0 !important;
      width: 100% !important;
      color: var(--fourth-text-color) !important;
    }
  }
}

.files-wrp {
  padding: 1em;
  display: flex;
  align-items: flex-start;
  flex-direction: column !important;
  gap: 0.5em;
  width: max-content;
  background-color: var(--secondary-background-color);
  border-radius: 0.7em;

  & div {
    display: flex;
    align-items: center;
    gap: 0.2em;
    & div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  & p {
    max-width: 15vw;
    background-color: transparent !important;
    padding: 0;
    color: var(--secondary-text-color);
    margin: 0;
  }
  & p:nth-child(1) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & span {
    display: inline-block;
    align-self: flex-end !important;
    color: var(--fourth-text-color) !important;
    font-size: 0.7em;
  }

  & input {
    display: block;
    width: 3em;
    aspect-ratio: 1/1;
    background-color: transparent;
    border: none;
    align-self: center !important;
    outline: none;
    cursor: pointer;
    background-image: url("@/share/assets/icons/arrowDownIcon.svg");
    background-size: 40%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--primary-color);
    border-radius: 50%;
  }
}

.messenger__resiver__avatar {
  & p,
  img {
    display: flex;
    margin: 0;
    padding: 0;
    padding: 0.4em;
    font-size: 1.2em;
    width: 2em;
    aspect-ratio: 1/1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #707070;
    border-radius: 50%;
    color: var(--text-color);
  }
}

.messenger__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 20em;

  &__form {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1em;

    & label {
      display: block;
      aspect-ratio: 1/1;
      background-image: url("@/share/assets/icons/filesIcon.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    & input[type="file"] {
      display: none;
    }

    & textarea {
      display: block;
      width: 50vw;
      resize: none;
      border: none;
      outline: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & input[type="submit"] {
      background-image: url("@/share/assets/icons/subtract.svg");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    & label,
    input[type="submit"] {
      width: 1em;
      aspect-ratio: 1/1;
    }

    & input {
      outline: none;
      border: navajowhite;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.edit-button {
  background-image: url("@/share/assets/icons/checkedPrimary.svg") !important;
}

.messenger__edit {
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 1em 1em 0 1em;
  & div:nth-child(1) {
    width: 1.75em;
    display: flex;
    align-items: center;
  }
  & div:nth-child(2) {
    display: flex;
    flex-direction: column;
    & p {
      margin: 0;
    }
    & p:nth-child(1) {
      color: #979da0;
      font-size: 0.9em;
    }
    & p:nth-child(2) {
      width: 50vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  & div:nth-child(3) {
    & button {
      position: relative;
      width: 1em;
      aspect-ratio: 1/1;
      border: none;
      outline: none;
      background-color: transparent;
      &::after,
      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 0.15em;
        height: 2em;
        content: "";
        background-color: #979da0;
      }
      &::before {
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
      }
    }
  }
}

.delivered {
  background-image: url("@/share/assets/icons/deliveredIcon.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.readed {
  background-image: url("@/share/assets/icons/readed.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.msgDelivered {
  position: relative;
  width: 4em;

  &::after {
    position: absolute;
    content: "";
    width: 1.3em;
    aspect-ratio: 1/1;
    top: 50%;
    right: -0.5em;
    transform: translate(0, -50%);
    background-image: url("@/share/assets/icons/deliveredIcon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.msgReaded {
  position: relative;
  width: 4em;

  &::after {
    position: absolute;
    content: "";
    width: 1.3em;
    aspect-ratio: 1/1;
    top: 50%;
    right: -0.5em;
    transform: translate(0, -50%);
    background-image: url("@/share/assets/icons/readed.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.deleted-message {
  font-style: italic;
}

.newMessage {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 0.5em;
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--weekend-color);
    border-radius: 50%;
  }
}

.messenger__center {
  display: grid;
  place-content: center;
  text-align: center;
  background-color: var(--background-color);

  & img {
    aspect-ratio: 1/1;
    width: 6em;
    place-self: center;
  }

  & p {
    width: 70%;
    place-self: center;
    margin: 0;
    font-size: 1.5em;
  }
}

.orange {
  background-color: #f79236 !important;
  color: var(--secondary-background-color) !important;
}

.grey {
  background-color: #738086 !important;
  color: var(--secondary-background-color) !important;
}

.black {
  background-color: #2f3c42 !important;
  color: var(--secondary-background-color) !important;
}

.red {
  background-color: #cd5322 !important;
  color: var(--secondary-background-color) !important;
}

.darkgray {
  background-color: #2f3c42 !important;
  color: var(--secondary-background-color) !important;
}

.contextMenu {
  display: flex;
  flex-direction: column;
  gap: 1em;
  & div {
    display: flex;
    align-items: center;
    gap: 0.3em;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }

    & p {
      color: var(--primary-color);
      margin: 0;
    }

    & img {
      width: 1.2em;
    }
  }
}

.delete-confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #00000036;
}

.self {
  align-self: flex-end !important;
}

.member {
  align-self: flex-start !important;
}
</style>
