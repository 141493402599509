<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { textAreaComp } from "@/share/UI";

const store = useStore();
const emit = defineEmits(["close"]);
const __wrp = ref();
const selectedOrg = ref(false)
const persList = computed(() => {
  const arr: { orgName: string, personal: { name: string, position: string, value: number }[] }[] = []
  const orgs = store.state.personal.organizations
  for (let o = 0; o < orgs.length; o++) {
    arr.push({ orgName: orgs[o].shortName, personal: [] })
    for (let d = 0; d < orgs[o].departments.length; d++) {
      const index = orgs[o].departments[d].personal.findIndex(p => p.id === orgs[o].headId)
      if (index !== -1) {
        for (let p = 0; p < orgs[o].departments[d].personal.length; p++) {
          arr[o].personal.push({ name: `${orgs[o].departments[d].personal[p].shortName}`, position: orgs[o].departments[d].personal[p].position.name, value: store.state.personal.organizations[o].departments[d].personal[p].id })
        }
      } else {
        const index = orgs[o].departments[d].personal.findIndex(p => p.id === orgs[o].departments[d].headId)
        if (index !== -1) arr[o].personal.push({ name: `${orgs[o].departments[d].personal[index].shortName}`, position: orgs[o].departments[d].personal[index].position.name, value: store.state.personal.organizations[o].departments[d].personal[index].id })
      }
    }
  }
  return arr
});

const personal = reactive<{ list: { name: string, position: string, value: number }[] }>({
  list: []
})

const select = ref<{ name: string, position: string | null, value: number | null | false } | null>();
const selectList = ref<{ name: string, position: string, value: number }[]>([]);
const comment = ref("");

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "task__actions") store.commit("titles/CLOSE_MODAL");
}

function doAction() {
  if (!selectList.value.length) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили список согласующих",
      type: "error",
    });
    return;
  }
  if (!comment.value || comment.value.length < 5) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не добавили комментарий",
      type: "error",
    });
    return;
  }
  const payload = {
    list: selectList.value.filter(p => p.value !== undefined || p.value == null).map(p => ({ personalId: p.value })),
    comment: comment.value,
    taskId: store.state.tasks.task?.id
  };
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/tasks/approvelist", {
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(({ response }) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("titles/CLOSE_MODAL");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

function selectedEvent(e: Event) {
  const { target } = (e) as Event & { target: HTMLSelectElement }
  const val = parseInt(target.value)
  personal.list = persList.value[val].personal
  selectedOrg.value = true
}

function selectedPers(e: Event) {
  const { target } = (e) as Event & { target: HTMLSelectElement }
  const val = parseInt(target.value)
  const index = personal.list.findIndex(p => p.value === val)
  if (index !== -1) {
    selectList.value.push(personal.list[index])
  }
  const index2 = personal.list.findIndex(p => val === p.value)
  select.value = null
  if (index2 !== -1) personal.list.splice(index2, 1)
}

onMounted(() => {
  __wrp.value?.focus();
});

function cancelSelect(index: number) {
  personal.list.push(selectList.value[index])
  personal.list = personal.list.sort((a, b) => a.value - b.value)
  selectList.value.splice(index, 1)
  select.value = null
}
</script>

<template>
  <div class="task__actions" @click="close($event, false)" @keyup.esc="close($event, false)" ref="__wrp" tabindex="0">
    <div class="setagreement">
      <div class="setagreement__wrp">
        <h2>Лист согласования</h2>
        <p class="required">Согласующие</p>
        <div class="personal-list">
          <div>            
            <label for="_selectAgreenent">Организация</label>
            <select @change="selectedEvent" id="_selectAgreenent">
              <option selected disabled :value="null">Выберите организацию</option>
              <option v-for="org, index in persList" :key="index" :value="index">{{ org.orgName }}</option>
            </select>
          </div>
          <div v-if="selectedOrg">
            <label for="_selectAgreenentPers">Согласующие</label>
            <select id="_selectAgreenentPers" v-model="select" @change="selectedPers">
              <option selected disabled :value="null">Выбрать согласующих</option>
              <option v-for="pers, index in personal.list" :key="index" :value="pers.value">{{ `${pers.name} ${pers.position}` }}</option>
            </select>
          </div>
        </div>
        <div class="list-agreement">
          <div v-if="selectList.length" v-for="(pers, index) in selectList" class="list-agreement-pers">
            <div>
              <p>
                {{ pers.name }}
              </p>
              <p>
                {{ pers.position }}
              </p>
            </div>
            <div>
              <svg  viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancelSelect(index)">
                <path d="M4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667ZM1.16732 15.5V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V15.5H1.16732Z"/>
              </svg>
            </div>
          </div>
        </div>
          <p>Комментарий</p>
          <textAreaComp :textVal="comment" @inputVal="(value: string) => (comment = value)"></textAreaComp>
          <div class="button-wrp">
            <button @click="$store.commit('titles/CLOSE_MODAL')" class="cancel">Отмена</button>
            <button @click="doAction" class="aproved">Отправить</button>
          </div>
        </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.personal-list {
  width: 20vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  & p {
    margin: 0;
  }

  & div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5em;

    & select {
      width: 100%;
    }
  }
}

.list-agreement {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1em;

  &-pers {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    & div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      & svg {
        width: 1.2em;
        aspect-ratio: 1/1;
        transition: transform 0.2s ease;
        cursor: pointer;
        fill: #738086;

        &:hover {
          fill: var(--primary-color);
        }

        &:active {
          transform: scale(0.9);
        }
      }

      & p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & p:last-child {
        color: var(--third-text-color);
      }
    }
  }
}


.task__actions {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
}

.setagreement {
  position: relative;

  &__wrp {
    height: max-content;
    border-radius: 0.4em;
    padding: 1em 5em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;

    & select {
      width: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    .selected {
      background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
    }

    .unselected {
      background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
    }
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.button-wrp {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;

  & button {
    flex-basis: 45%;
    display: block;
    padding: 1em 0;
    border: none;
    outline: none;
    color: var(--text-color);
    border-radius: 0.4em;
    cursor: pointer;
  }
}

.aproved {
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.cancel {
  background-color: var(--secondary-text-color);

  &:hover {
    background-color: var(--button-deactivate-color) !important;
    color: var(--text-hover-color);
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
