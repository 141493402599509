<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, ref, watch } from "vue";
import { capitalLetter } from "@/helpers/capitalLetter";

const store = useStore();

const departments = computed(() => store.state.personal.departments);
const positions = computed(() => store.state.personal.positions);
const rerender = ref(0);
const phoneRerender = ref(0);

const loaderState = ref(false);
const payload = ref<{
  firstName: string;
  lastName: string;
  surName: string;
  departmentId: number | null;
  positionId: number | null;
  contacts: {
    email: string;
    mobile: string;
    phone: string;
  };
  user: { roles: number[] };
}>({
  firstName: "",
  lastName: "",
  surName: "",
  departmentId: null,
  positionId: null,
  contacts: {
    email: "",
    mobile: "",
    phone: "",
  },
  user: { roles: [7] },
});

function setPosition(e: Event) {
  const { value } = e.target as HTMLInputElement;
  const id = parseInt(value);
  if (id === 0) {
    store.commit("titles/SET_ADDITIONAL_MODAL", "addPosition");
  } else {
  }
}

function setDepartment(e: Event) {
  const { value } = e.target as HTMLInputElement;
  const id = parseInt(value);
  if (id === 0) {
    store.commit("titles/SET_ADDITIONAL_MODAL", "createDepartment");
  } else {
  }
}

watch(store.state.titles, (val) => {
  if (val.positionId) {
    payload.value.positionId = val.positionId;
    let count = 0;
    const interval = setInterval(() => {
      count++;
      if (count > 10) return clearInterval(interval);
      const newPosition = positions.value.find((p) => p.id === val.positionId);
      if (newPosition) {
        payload.value.positionId = newPosition.id;
        clearInterval(interval);
        rerender.value++;
      }
    }, 100);
  }
  if (val.departmentId) {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      if (count > 10) return clearInterval(interval);
      const newDepartment = departments.value.find((p) => p.id === val.departmentId);
      if (newDepartment) {
        payload.value.departmentId = newDepartment.id;
        clearInterval(interval);
        rerender.value++;
      }
    }, 100);
  }
  if (!val.departmentId) {
    payload.value.departmentId = null;
  }
  if (!val.positionId) {
    payload.value.positionId = null;
  }
});

function createRequest(event: Event) {
  if (payload.value.positionId === null) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили должность",
      type: "error",
    });
    return;
  }

  if (payload.value.departmentId === null) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили должность",
      type: "error",
    });
    return;
  }

  loaderState.value = true;

  const data = {
    person: {
      departmentId: payload.value.departmentId,
      positionId: payload.value.positionId,
      firstName: payload.value.firstName,
      lastName: payload.value.lastName,
      surName: payload.value.surName,
    },
    newContacts: payload.value.contacts,
    roles: payload.value.user.roles,
  };
  doRequest("/personal/create/person", {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(data),
  })
    .then(() => {
      loaderState.value = false;
      payload.value.positionId = 0;
      payload.value.firstName = "";
      payload.value.lastName = "";
      payload.value.surName = "";
      payload.value.contacts.email = "";
      payload.value.contacts.phone = "";
      payload.value.user.roles = [7];
      phoneRerender.value++;
      store.commit("titles/CLOSE_MODAL");
    })
    .catch((error) => {
      loaderState.value = false;
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "create-request") store.commit("titles/CLOSE_MODAL");
}

function checkExistStr(str?: string) {
  if (!str) return "";
  return str;
}

function formatPhoneNumber(num: string) {
  const number = num.split("");
  return [
    checkExistStr(number[0]) ? `(${checkExistStr(number[0])}` : "",
    checkExistStr(number[1]),
    checkExistStr(number[2]) ? `${checkExistStr(number[2])})` : "",
    checkExistStr(number[3]),
    checkExistStr(number[4]),
    checkExistStr(number[5]) ? `${checkExistStr(number[5])}-` : "",
    checkExistStr(number[6]),
    checkExistStr(number[7]) ? `${checkExistStr(number[7])}-` : "",
    checkExistStr(number[8]),
    checkExistStr(number[9]),
    checkExistStr(number[10]),
  ].join("");
}

function inputChange(e: Event) {
  const event = e as unknown as InputEvent;
  const input = e.target as HTMLInputElement;
  if (event.data && /\D/.test(event.data)) return (input.value = formatPhoneNumber(input.value.replace(/\D/g, "")));
  if (input.value.length > 14) {
    const str = input.value.replace(/\(|\)|-|\D/g, "");
    input.value = formatPhoneNumber(str.slice(0, -1));
    return;
  }
  if (event.inputType === "insertText") {
    const str = input.value.replace(/\(|\)|-/g, "");
    if (/\d{10,}/.test(str)) payload.value.contacts.mobile = str;
    return (input.value = formatPhoneNumber(str));
  } else if (event.inputType === "deleteContentBackward") {
    if (/-|\)|\(/.test(input.value[input.value.length - 1])) input.value = input.value.slice(0, -1);
  } else if (event.inputType === "historyUndo") {
  } else if (event.inputType === "deleteContentForward") {
  }
}

function mainRole(e: Event) {
  const { value } = e.target as HTMLInputElement;
  const val = parseInt(value);
  if (isNaN(val)) return;
  const additional = payload.value.user.roles.filter((s) => s === 6 || s === 3 || s === 4);
  payload.value.user.roles = [...additional, val];
}

function additionalRole(e: Event) {
  const { value } = e.target as HTMLInputElement;
  const val = parseInt(value);
  if (isNaN(val)) return;
  const index = payload.value.user.roles.findIndex((s) => s === val);
  if (index === -1) payload.value.user.roles.push(val);
  else payload.value.user.roles.splice(index, 1);
}

onMounted(() => {
  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape") store.commit("titles/CLOSE_MODAL");
  });
});
</script>

<template>
  <div class="create-request" @mousedown="close">
    <form @submit.prevent="createRequest" class="form">
      <button type="button" class="close" @click="close($event, true)"></button>
      <div class="create-request__wrp">
        <p class="form__title extra">Новый сотрудник</p>
        <div class="form__content create-request__select">
          <div>
            <p class="form__title">Фамилия:</p>
            <input type="text" required v-model="payload.lastName" @input="capitalLetter" />
          </div>
          <div>
            <p class="form__title">Имя:</p>
            <input type="text" required v-model="payload.firstName" @input="capitalLetter" />
          </div>
          <div>
            <p class="form__title">Отчество:</p>
            <input type="text" required v-model="payload.surName" @input="capitalLetter" />
          </div>
        </div>
        <div class="form__content create-request__select">
          <div>
            <p class="form__title">Номер телефона:</p>
            <div class="register-form__number">
              <span>+7</span>
              <input type="text" @input="inputChange" required :key="phoneRerender" />
            </div>
          </div>
          <div>
            <p class="form__title">Электронная почта:</p>
            <input type="email" required v-model="payload.contacts!.email" />
          </div>
        </div>
        <div class="form__content create-request__select create-request__department">
          <div>
            <p class="form__title">Структурное подразделение:</p>
            <select class="form__scroll" required v-model="payload.departmentId" @change="setDepartment" :key="rerender">
              <option :value="null" disabled selected>Выберите подразделение</option>
              <option v-for="department in departments" :key="department.name" :value="department.id">{{ department.name }}</option>
              <option value="0" :style="{ backgroundColor: '#f793367c', color: '#000' }">{{ `Добавить подразделение` }}</option>
            </select>
          </div>
          <div>
            <p class="form__title">Должность:</p>
            <select class="form__scroll" required v-model="payload.positionId" @change="setPosition" :key="rerender">
              <option :value="null" disabled selected>Выберите должность</option>
              <option v-for="(position, index) in positions" :key="index" :value="position.id">{{ position.name }}</option>
              <option value="0" :style="{ backgroundColor: '#f793367c', color: '#000' }">{{ `Добавить должность` }}</option>
            </select>
          </div>
        </div>
        <div :class="{ form__contact: true, active: true }">
          <div class="form__roles">
            <div class="form__roles__wrp">
              <p class="form__title">Роль в системе:</p>
              <label for="roles1" title="Имеет право редактировать, модерировать запросы в рамках отдела" v-if="$store.state.personal.organization.headId === null">
                <input type="radio" name="roles" id="roles1" value="1" @change="mainRole" />Руководитель
              </label>
              <label for="roles2" title="Имеет право редактировать, модерировать запросы в рамках отдела">
                <input type="radio" name="roles" id="roles2" value="2" @change="mainRole" />Заместитель руководителя
              </label>
              <label for="roles3" title="Имеет право редактировать, модерировать запросы в рамках отдела">
                <input type="radio" name="roles" id="roles3" value="5" @change="mainRole" />Начальник отдела
              </label>
              <label for="roles4" title="Специалист отдела"> <input type="radio" name="roles" id="roles4" value="7" @change="mainRole" checked />Специалист </label>
            </div>
            <div class="form__roles__wrp">
              <p class="form__title">Дополнительные роли:</p>
              <label
                v-for="(role, index) in $store.state.personal.roles.filter((r) => r.id === 3 || r.id === 4 || r.id === 6)"
                :key="index"
                :for="`_additionalRoles${index}`"
                :title="role.descriptions"
              >
                <input type="checkbox" name="additional" :id="`_additionalRoles${index}`" :value="role.id" @change="additionalRole" />{{ role.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="form__submit">
          <button v-if="!loaderState" type="submit" class="form__buttons">Создать</button>
          <button v-else type="submit" class="form__buttons__loader">Создать<span></span><span></span><span></span></button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.form__contact {
  visibility: hidden;
}

.active {
  visibility: visible;
}

.form {
  position: relative;
  border-radius: 0.4em;

  &__scroll {
    max-height: 10vh;
    overflow: hidden;
  }

  &__roles {
    width: 100%;
    display: flex;
    gap: 1em;

    &__wrp {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
      gap: 0.4em;

      & label {
        flex-basis: 33%;
        flex-grow: 0;
        display: flex;
        align-items: center;
        gap: 0.5em;

        & input {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.form__title {
  margin: 0;
  font-size: 1.1em;
  text-align: left;
  color: var(--secondary-border-color);
}

.form__date-button {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.form__content {
  gap: 1em;
}

.form__submit-button {
  justify-self: flex-end;
  align-self: center;
}

.form__buttons {
  display: block;
  width: max-content;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0.6em 1em;
  background-color: var(--primary-color);
  border-radius: 0.4em;
  color: var(--text-color);
  cursor: pointer;
}

.form__buttons__loader {
  @extend .form__buttons;
  position: relative;
  color: var(--primary-color);
  & span {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0.6em;
    aspect-ratio: 1/1;
    color: var(--text-color);
    background-color: #fff;
    border-radius: 50%;
  }
  & span:nth-child(1) {
    left: calc(50% - 15%);
    animation: load 1.5s linear 0s infinite;
  }
  & span:nth-child(2) {
    left: 50%;
    animation: load 1.5s linear 0.3s infinite;
  }
  & span:nth-child(3) {
    left: calc(50% + 15%);
    animation: load 1.5s linear 0.6s infinite;
  }
}

@keyframes load {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form__buttons:hover {
  background-color: var(--primary-color);
}

.extra {
  font-size: 1.5em;
}

.create-request {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkening-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  z-index: 6;

  &__position {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  &__department {
    display: flex;
    gap: 3em !important;

    & div {
      & select {
        width: 20vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  & form {
    padding: 2em;
    display: flex;
    gap: 1em;
    justify-content: space-between;
    background-color: var(--secondary-background-color);

    & input[type="text"]:first-letter {
      text-transform: capitalize;
    }

    & input[type="text"],
    input[type="email"],
    input[type="number"],
    select {
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.8em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
    }

    & input[type="file"] {
      display: none;
    }
  }

  &__wrp {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__select {
    display: flex;
    gap: 1em;

    & div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      box-sizing: border-box;
      gap: 1em;

      & select {
        border: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        outline: none;
        border: 0.1em solid var(--fourth-border-color);
        padding: 0.2em 0.4em;
        border-radius: 0.4em;

        & option {
          margin: 0;
          flex-basis: 48%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  &__datetime {
    display: flex;
    flex-direction: column;
    align-items: center;

    & input[type="datetime-local"] {
      border: none;
      outline: none;
      padding: 0.4em 0.5em;
      border: 0.1em solid var(--fourth-border-color);
      border-radius: 0.4em;
    }
  }

  &__user {
    position: relative;
    outline: none;
    border: none;
    display: block;
    width: 4em;
    aspect-ratio: 16/7;
    background-color: transparent;
    border-radius: 2em;
    background-color: var(--fourth-background-color);
    gap: 0.4em;

    &::after {
      content: "";
      width: 1.7em;
      aspect-ratio: 1/1;
      border-radius: 50%;
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      background-color: var(--third-background-color);
      transition: all 0.1s;
    }
  }

  &__user-cheked {
    background-color: var(--button-background-button);

    &::after {
      left: calc(100% - 1.7em);
      right: 0 !important;

      background-color: var(--primary-color);
    }
  }
}

.form__submit {
  flex-grow: 1;
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.register-form__number {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  border: 0.1em solid var(--fourth-border-color) !important;
  border-radius: 0.4em;
  gap: 0 !important;

  & input {
    display: block;
    border: none !important;
    margin-left: 0.2em !important;
    padding: 0.4em 0 !important;
  }

  & span {
    display: block;
    margin-left: 0.4em !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
