<script setup lang="ts"></script>

<template>
  <img @click="$router.back" :src="require(`@/share/assets/icons/arrowReturnIcon.svg`)" />
  <p @click="$router.back">Назад</p> 
</template>

<style lang="scss" scoped>
  p {
    margin: 0;
    color: var(--primary-color);
  }
  img {
    width: 0.7em;
  }
</style>
