import { Module } from "vuex";

export const userState: Module<iStore.User, iStore.RootState> = {
  namespaced: true,
  state: {
    connectAttempt: 1,
    connected: true,
    failReconnect: false,
    info: {
      id: 0,
      isAdmin: false,
      isDepHead: false,
      isHead: false,
      isManager: false,
      isVice: false,
      avatar: null,
      organization: {
        orgId: 0,
        fullName: "",
        inn: "",
        kpp: "",
        legalAdress: "",
        shortName: "",
        head: {
          departmentId: 0,
          id: 0,
          shortName: ""
        }
      },
      roles: [],
    }
  },
  mutations: {
    SET_USER(state, payload) {
      payload.isHead = false;
      payload.isAdmin = false;
      payload.isVice = false;
      payload.isDepHead = false;
      for (let i = 0; i < payload.roles.length; i++) {
        if (["Руководитель"].indexOf(payload.roles[i].name) !== -1) payload.isHead = true;
        if (["Заместитель"].indexOf(payload.roles[i].name) !== -1) payload.isVice = true;
        if (["Начальник отдела"].indexOf(payload.roles[i].name) !== -1) payload.isDepHead = true;
        if (["Администратор"].indexOf(payload.roles[i].name) !== -1) payload.isAdmin = true;
        if (["Кадровая служба"].indexOf(payload.roles[i].name) !== -1) payload.isManager = true;
      }
      state.info = payload;
    },
    SET_CONNECTED(state) {
      state.connected = true
      state.connectAttempt = 1
    },
    SET_DISCONNECTED(state) {
      state.connected = false
    },
    INCREMENT_ATTEMPT(state) {
      state.connectAttempt++
    },
    FAIL_RECONNECT(state) {
      state.failReconnect = true
    },
  },
  getters: {
    GET_TASK_RIGHT(state) {
      for (let i = 0; i < state.info.roles.length; i++) {
        if (["Начальник отдела", "Заместитель", "Руководитель"].indexOf(state.info.roles[i].name) !== -1) return true;
      }
    },
  },
};
