<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const store = useStore();
const __wrp = ref();
const personal = computed(() => store.state.personal.personalList.find((person) => person.id === parseInt(route.params.index as string)));

const today = ref(getDate());
const select = ref(false);
const expandSelect = ref<number | null>(null);
const action = ref<"setNull" | "replace" | null>(null);

const payload = ref({
  personalId: null as null | number,
  action: null as null | string,
  date: null as null | string,
});

const isHead = ref((personal.value && personal.value.roles.findIndex((r) => r.id === 1) !== -1) || false);
const isVice = ref((personal.value && personal.value.roles.findIndex((r) => r.id === 2) !== -1) || false);
const isDepartmentHead = ref((personal.value && personal.value.roles.findIndex((r) => r.id === 5) !== -1) || false);

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "task__actions") store.commit("titles/CLOSE_MODAL");
}

function getRole(args: [boolean, boolean, boolean]) {
  let index: 0 | 1 | 2 = 2;
  const map = {
    0: "руководителем",
    1: "заместителем",
    2: "начальником отдела",
  };
  for (let i = 0; i < args.length; i++) {
    if (args[i]) index = i as 0 | 1 | 2;
  }
  return map[index];
}

function getDate() {
  const regex = new RegExp(/(\d{4}-\d{2}-\d{2})/);
  const match = new Date().toISOString().match(regex);
  if (match?.length) {
    return match[0];
  } else return "";
}

function changeToggleUp() {
  select.value = true;
}

function changeToggleDown(e: Event) {
  select.value = false;
  (e.target as HTMLElement).blur();
}

function expander(num: number) {
  if (expandSelect.value === num) return (expandSelect.value = null);
  return (expandSelect.value = num);
}

function firePerson() {
  store.commit("titles/CLOSE_MODAL");
  if (!personal.value) return;
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/personal/person/fire", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ id: personal.value.id }),
  })
    .then(() => {
      store.commit("componentLoader/TOGGLE_STATE");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

function activateSelect(e: Event) {
  e.preventDefault();
  const button = e.target as HTMLButtonElement;
  if (action.value === "replace") {
    action.value = "setNull";
    button.classList.remove("fire__substitute__checked");
  } else {
    action.value = "replace";
    button.classList.add("fire__substitute__checked");
  }
  return;
}

onMounted(() => {
  __wrp.value?.focus();
});
</script>

<template>
  <div class="fire" @click="close" @keyup.esc="$emit('close', false)" ref="__wrp" tabindex="0">
    <div class="fire__wrp">
      <h3>Уволить сотрудника?</h3>
      <div v-if="isHead || isDepartmentHead || isVice" class="fire__blocks">
        <p>Данный сотрудник является {{ getRole([isHead, isVice, isDepartmentHead]) }}</p>
        <div class="fire__question">
          <label for="_fire-button" class="fire__button">Назначить другого сотрудника</label>
          <button class="fire__substitute" id="_fire-button" @click="activateSelect"></button>
        </div>
        <div v-if="action === 'replace'">
          <select v-model="payload.personalId" @focus="changeToggleUp" @blur="changeToggleDown">
            <option
              v-for="(item, index) in $store.state.personal.personalList.filter((person) => person.department.id === person.department.id && person.id !== personal?.id)"
              :key="index"
              :value="item"
            >
              {{ item.lastName }} {{ item.firstName }} {{ item.surName }}
            </option>
          </select>
        </div>
        <div class="fire__dates__date">
          <label for="fire_date">Дата увольнения</label>
          <input type="date" id="_date" v-model="payload.date" :min="today" />
        </div>
      </div>
      <div class="fire__blocks">
        <h4>У сотрудника больше не будет доступа к личному кабинету</h4>
        <div class="fire__buttons">
          <button @click="$store.commit('titles/CLOSE_MODAL')" class="cancel">Отмена</button>
          <button @click="firePerson()" class="approve">Подтвердить</button>
        </div>
      </div>
    </div>
    <button type="button" class="close" @click="close($event, true)"></button>
  </div>
</template>

<style lang="scss" scoped>
.fire {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
  display: grid;
  place-content: center;

  &__wrp {
    border-radius: 0.4em;
    padding: 2em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: max-content;
  }
  &__substitute {
    position: relative;
    outline: none;
    border: none;
    display: block;
    width: 2.892em;
    aspect-ratio: 16/7;
    background-color: var(--fourth-background-color);
    border-radius: 2em;
    gap: 0.4em;

    &::after {
      content: "";
      width: 1.3em;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: var(--third-background-color);
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      transition: all 0.1s;
    }
  }
  &__substitute__checked {
    background-color: var(--button-background-button);

    &::after {
      left: calc(100% - 1.3em);
      right: 0 !important;
      background-color: var(--primary-color);
    }
  }
  &__buttons {
    display: flex;
    gap: 1em;
    justify-content: center;
    & button {
      flex-basis: 48%;
      display: block;
      padding: 1em;
      border: none;
      outline: none;
      background-color: var(--primary-color);
      color: var(--text-color);
      border-radius: 0.4em;
      text-align: center;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:active {
        transform: scale(0.95);
      }

      &:hover {
        background-color: var(--primary-hover-color);
      }
    }
    & button:nth-child(1) {
      background-color: var(--secondary-text-color);
      &:hover {
        background-color: var(--button-deactivate-color);
      }
    }
  }
  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 1em;
    & label,
    & p {
      display: block;
      margin: 0;
      padding: 0;
    }

    & select {
      width: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.5em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    & input[type="date"] {
      outline: none;
      justify-self: auto;
      padding: 0.5em;
      outline: none;
      border-radius: 0.4em;
      word-wrap: break-word !important;
      border: 0.1em solid var(--fourth-border-color);
      background-color: var(--secondary-background-color);
    }
  }
  &__dates {
    display: flex;
    gap: 1em;
    flex-direction: row;
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.fire__question {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
