import { doRequest } from "@/helpers";
import { Module } from "vuex";

export const personalState: Module<
  {
    personalList: iStore.Personal[];
    id: number;
    records: { id: number; type: string }[];
    departments: iStore.Departments[];
    positions: iStore.Positions[];
    roles: { id: number; name: string; descriptions: string }[];
    organizations: Organization.iOrganization[]
    organization: Organization.iOrganization
  },
  iStore.RootState
> = {
  namespaced: true,
  state: {
    personalList: [],
    id: 0,
    records: [],
    departments: [],
    positions: [],
    roles: [],
    organizations: [],
    organization: {} as Organization.iOrganization
  },
  mutations: {
    SET_ORGANIZATION(state, payload) {
      state.organization = payload.data
    },
    SET_ORGANIZATIONS(state, payload) {
      if (payload.type === "updated") {
        const index = state.organizations.findIndex((o) => o.id === payload.data.id)
        if (index !== -1) state.organizations[index] = payload.data
      } else if (payload.type === "created") {
        state.organizations.push(payload.data)
      } else if (payload.type === "deleted") {
        const index = state.organizations.findIndex((o) => o.id === payload.data.id)
        if (index !== -1) state.organizations.splice(index, 1)
      }
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    SET_ONLINE(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        for (let a = 0; a < state.personalList.length; a++) {
          if (state.personalList[a].id === payload[i].personalId) {
            state.personalList[a].online = payload[i].status;
            state.personalList[a].lastSeen = payload[i].timestamp;
          }
        }
      }
    },
    SET_OFFLINE(state, payload) {
      for (let i = 0; i < state.personalList.length; i++) {
        if (state.personalList[i].id === payload.personalId) {
          state.personalList[i].online = false;
          state.personalList[i].lastSeen = payload.lastSeen;
          return;
        }
      }
    },
    SET_PERSONAL_LIST(state, payload) {
      state.personalList = payload;
    },
    SET_DATA(state, payload) {
      if (payload.departments) state.departments = payload.departments;
      if (payload.positions) state.positions = payload.positions;
      if (payload.roles) state.roles = payload.roles;
      if (payload.records) state.records = payload.records;
      if (payload.organizations) state.organizations = payload.organizations.map((o: any) => o.suborg);
      if (payload.organization) state.organization = payload.organization
    },
    SET_DEPARTMENT(state, payload) {
      if (payload.type === "updated") {
        const index = state.departments.findIndex((d) => d.id === payload.data.id)
        if (index !== -1) {
          state.departments[index] = payload.data
        }
      } else if (payload.type === "created") {
        state.departments.push(payload.data)
      } else if (payload.type === "deleted") {
        const index = state.departments.findIndex((d) => d.id === payload.data.id)
        if (index !== -1) {
          state.departments.splice(index, 1)
        }
      }
    },
    SET_PERSON(state, payload) {
      if (payload.type === "updated") {
        const personIndex = state.personalList.findIndex((p) => p.id === payload.data.id)
        if (personIndex !== -1) {
          state.personalList[personIndex] = payload.data
        }
      } else if (payload.type === "created") {
        state.personalList.push(payload.data)
      } else if (payload.type === "deleted") {
        const personIndex = state.personalList.findIndex((p) => p.id === payload.data.id)
        if (personIndex !== -1) {
          state.personalList.splice(personIndex, 1)
        }
      }
    },
    SET_PERSON_CONTACTS(state, payload) {
      const personIndex = state.personalList.findIndex((p) => p.id === payload.data.personalId)
      if (personIndex !== -1) {
        state.personalList[personIndex].contacts = payload.data
      }
    },
    SET_PERSON_RECORD(state, payload) {
      const personIndex = state.personalList.findIndex((p) => p.id === payload.data.id)
      if (personIndex !== -1) {
        state.personalList[personIndex].log.push(payload.data)
      }
    },
    SET_PERSON_ROLES(state, payload) {
      const personIndex = state.personalList.findIndex((p) => p.id === payload.data.id)
      if (personIndex !== -1) {
        state.personalList[personIndex].roles = payload.data.roles
      }
    },
    SET_POSITION(state, payload) {
      state.positions.push(payload.data)
    }
  },
  actions: {
    GET_DEPARTMENTS({ commit }) {
      doRequest("/personal/get/departments", { method: "GET" })
        .then(({ response }) => {
          commit("SET_DATA", response)
        })
        .catch(error => { })
    },
    GET_ALL_PERSONAL({ commit }) {
      doRequest("/personal/get", { method: "GET" })
        .then(({ response }) => {
          commit("chat/SET_PERSONAL_LIST", response.personal, { root: true })
          commit("SET_PERSONAL_LIST", response.personal)
          commit("SET_DATA", response)
        })
        .catch(error => { })
    },
  },
};
