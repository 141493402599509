<script lang="ts" setup>
import { useStore } from "@/entities";
import { doRequest } from "@/helpers";
import { computed, onMounted, ref } from "vue";
import { textAreaComp } from "@/share/UI";

const store = useStore();
const emit = defineEmits(["close"]);
const __wrp = ref();
const userInfo = ref(store.state.user.info);
const persInfo = store.state.personal.personalList.find((pers) => pers.id === userInfo.value.id);
const persList = computed(() => {
  const arr: { name: string, position: string | null, value: number | null | false }[] = []
  const orgs = store.state.personal.organizations
  for (let o = 0; o < orgs.length; o++) {
    arr.push({ name: orgs[o].shortName, position: null, value: false })
    for (let d = 0; d < orgs[o].departments.length; d++) {
      arr.push({ name: `${orgs[o].departments[d].name}`, position: null, value: null })
      for (let p = 0; p < orgs[o].departments[d].personal.length; p++) {
        arr.push({ name: `${orgs[o].departments[d].personal[p].lastName} ${orgs[o].departments[d].personal[p].firstName[0]}. ${orgs[o].departments[d].personal[p].surName[0]}.`, position: orgs[o].departments[d].personal[p].position.name, value: store.state.personal.organizations[o].departments[d].personal[p].id })
      }
    }
  }
  return arr
});

const selectId = ref<number[]>([]);
const select = ref<number>();
const comment = ref("");

function close(e: Event, close?: boolean) {
  if (close) return store.commit("titles/CLOSE_MODAL");
  const target = e.target as HTMLElement;
  if (target.className === "task__actions") store.commit("titles/CLOSE_MODAL");
}

function doAction() {
  if (!selectId.value.length) {
    store.commit("events/PUSH_EVENT", {
      id: undefined,
      message: "Вы не заполнили список согласующих",
      type: "error",
    });
    return;
  }

  const payload = { persId: selectId.value, comment: comment.value, taskId: store.state.tasks.task?.id, userId: store.state.user.info.id };
  store.commit("componentLoader/TOGGLE_STATE");
  doRequest("/tasks/update/executor", {
    headers: { "Content-Type": "application/json; charset=utf-8" },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(({ response }) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("titles/CLOSE_MODAL");
    })
    .catch((error) => {
      store.commit("componentLoader/TOGGLE_STATE");
      store.commit("events/PUSH_EVENT", {
        id: undefined,
        message: error,
        type: "error",
      });
    });
}

onMounted(() => {
  __wrp.value?.focus();
});
function agreemented() {
  const control = persList.value.find((pers) => pers.value === persInfo?.department.responsibleId);
  const depHead = persList.value.find((pers) => pers.value === persInfo?.department.headId);

  if (userInfo.value.isDepHead) {
    return [{ name: control?.name, id: control?.value }];
  }
  if (!userInfo.value.isHead && !userInfo.value.isVice && !userInfo.value.isDepHead) {
    return [{ name: depHead?.name, id: depHead?.value }];
  }
}
function setPers() {
  if (select.value !== undefined) {
    if (selectId.value.indexOf(select.value) == -1) {
      selectId.value?.push(select.value);
    }
    select.value = undefined;
  }
}
function agreementList(id: number[]) {
  let arr: iStore.Personal[] = [];

  id?.forEach((el) => {
    const pers = persList.value.find((pers) => pers.value === el);
    //
    pers && arr.push();
  });
  return arr;
}
</script>

<template>
  <div class="task__actions" @click="close($event, false)" @keyup.esc="close($event, false)" ref="__wrp" tabindex="0">
    <div class="setagreement">
      <div class="setagreement__wrp">
        <h2>Лист согласованияSSSS</h2>
        <p class="required">Согласующие</p>
        <p>Комментарий</p>
        <textAreaComp :textVal="comment" @inputVal="(value: string) => (comment = value)"></textAreaComp>
        <div class="button-wrp">
          <button @click="close($event, false)" class="cancel">Отмена</button>
          <button @click="doAction" class="aproved">Отправить</button>
        </div>
      </div>
      <button type="button" class="close" @click="close($event, true)"></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.circle-container {
  position: relative;
  display: flex;
  align-items: center;
}

.circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #3498db;
  border: 0.1em solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-text {
  margin-left: 10px;
}

.line {
  position: absolute;
  width: 0.2em;
  height: 1em;
  background-color: #000;
  top: 2em;
  left: 0.9em;
}

p {
  margin: 0;
}

.list-agreement {
  display: flex;
  justify-content: space-between;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    & img {
      width: 1.2em;
      aspect-ratio: 1/1;
      transition: transform 0.2s ease;
      cursor: pointer;

      &:active {
        transform: scale(0.9);
      }
    }

    & p:last-child {
      color: var(--third-text-color);
    }
  }
}

.task__actions {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
  display: grid;
  place-content: center;
}

.setagreement {
  position: relative;

  &__wrp {
    border-radius: 0.4em;
    padding: 2em 5em;
    background-color: var(--secondary-background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: max-content;

    & select {
      cursor: pointer;
      outline: none;
      border: none;
      border: 0.1em solid var(--fourth-border-color);
      padding: 0.4em 0.4em;
      border-radius: 0.4em;
      color: var(--secondary-text-color);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-size: 1em;
      background-position: calc(100% - 1em) center;
      background-repeat: no-repeat;
    }

    .selected {
      background-image: url("@/share/assets/icons/arrowStickUpIcon.svg") !important;
    }

    .unselected {
      background-image: url("@/share/assets/icons/arrowStickDownIcon.svg") !important;
    }
  }
}

.close {
  display: block;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  width: 1.5em;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-image: url("@/share/assets/icons/closeIcon.svg");
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: 1em;
  top: 1em;
  transition: transform 0.2s ease;

  &:hover {
    background-image: url("@/share/assets/icons/closeDarkIcon.svg");
  }

  &:active {
    transform: scale(0.8);
  }
}

.button-wrp {
  display: flex;
  gap: 0.5em;

  & button {
    display: block;
    padding: 1em 0;
    width: 100%;
    border: none;
    outline: none;
    color: var(--text-color);
    border-radius: 0.4em;
    cursor: pointer;
  }
}

.aproved {
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-hover-color) !important;
  }

  &:active {
    transform: scale(0.95);
  }
}

.cancel {
  background-color: var(--secondary-text-color);

  &:hover {
    background-color: var(--button-deactivate-color) !important;
    color: var(--text-hover-color);
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
